import Panel from "../components/Panel";
import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import "../assets/scss/pages/MySolarProduction.scss";
import LabeledBarChart from "../components/LabeledBarChart";
import { useSwitcherContext } from "../contexts/SwitcherContext";
import { COLORS, TimeLabels, getFormatedDatetime } from "../utils/toolbox";
import {
  MeasuredData,
  MonitoringResponse,
  useLazyGetManualMonitoringWithFilterDataQuery,
  useLazyGetMonitoringActualAndForecastDataQuery,
} from "../services/ems.service";
import EmsAlert from "../components/EmsAlert";
import { Box, Skeleton } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { store } from "../store/config";
import { getSelectedTopo } from "../reducers/topologyReducer";
import { Switcher } from "../components/Switcher";
import moment from "moment";
//svgs
import { ReactComponent as Sun } from "../assets/icons/ifpen-sun.svg";
import { ReactComponent as BatteryDown } from "../assets/icons/ifpen-battery-down.svg"
import { ReactComponent as Pylon } from "../assets/icons/ifpen-pylon.svg";
import { ResponsiveLayout } from "../layouts/ResponsiveLayout";
ChartJS.register(CategoryScale, LinearScale, BarElement, zoomPlugin);

const MySolarConsumptionPage: React.FC = () => {
  const { unit, period, switcherDate, setSwitcherDate } =
    useSwitcherContext();

  const [
    getMonitoringWithForecast,
    {
      isError: monitoringWithForecastIsError,
      isLoading: monitoringWithForecastIsLoading,
    },
  ] = useLazyGetMonitoringActualAndForecastDataQuery();

  const manualMonitoringResponse =
    useLazyGetManualMonitoringWithFilterDataQuery();
  const [data, setData] = useState<{
    outputDTOActual: MonitoringResponse,
    outputDTOForeacasted: MonitoringResponse
  }>();

  const topologyId = getSelectedTopo(store.getState()).id;
  const navigate = useNavigate();
  useEffect(() => {
    if (topologyId == "") {
      navigate("/redirect");
    }
  }, [topologyId]);

  useEffect(() => {
    if (!switcherDate) setSwitcherDate(new Date());

    const fetchData = async () => {
      const data = await getMonitoringWithForecast({
        date: moment(switcherDate).format('YYYY-MM-DD'),
        topologyId: topologyId,
      });
      return data.data;
    };
    fetchData()
      .then((res) => {
        setData(res);
      })
      .catch((err) => console.log(err));
  }, [switcherDate]);

  const labelsConsum: TimeLabels[] = [];
  const pConsoFromProd: string[] = [];
  const pConsoFromBat: string[] = [];
  const pConsoFromGrid: string[] = [];

  const pConsoFromProdForecast: string[] = [];
  const pConsoFromBatForecast: string[] = [];
  const pConsoFromGridForecast: string[] = [];

  //Actual
  if (data && data.outputDTOActual && data.outputDTOActual[period]) {
    const actual = data.outputDTOActual;
    //conso
    (actual[period]["pConsoFromProd" + unit] as MeasuredData[]).forEach((item: MeasuredData) => {
      getFormatedDatetime(item, period, labelsConsum);
      pConsoFromProd.push(item.energy.toFixed(1));
    });

    (actual[period]["pConsoFromGrid" + unit]  as MeasuredData[]).forEach((item: MeasuredData) => {
      pConsoFromGrid.push(item.energy.toFixed(1));
    });

   ( actual[period]["pConsoFromBat" + unit]  as MeasuredData[]).forEach((item: MeasuredData) => {
      pConsoFromBat.push(item.energy.toFixed(1));
    });
  }
  //forecast
  if (data && data.outputDTOForeacasted && data.outputDTOForeacasted[period]) {
    const forecasted = data.outputDTOForeacasted;
    const addItem = (array: string[], item: MeasuredData) => {
      array.push(item.energy.toFixed(1));
    };

    (forecasted[period]["pConsoFromProd" + unit]  as MeasuredData[]).forEach((item: MeasuredData) => {
      getFormatedDatetime(item, period, labelsConsum);
      addItem(pConsoFromProdForecast, item);
    });

    (forecasted[period]["pConsoFromGrid" + unit]  as MeasuredData[]).forEach((item: MeasuredData) => {
      addItem(pConsoFromGridForecast, item);
    });

    (forecasted[period]["pConsoFromBat" + unit]  as MeasuredData[]).forEach((item: MeasuredData) => {
      addItem(pConsoFromBatForecast, item);
    });
  }

  const graphConso = {
    labels: labelsConsum,
    datasets: [
      {
        key: 4,
        label: "Autoconsommation directe",
        data: pConsoFromProd,
        image: Sun,
        darkImage: Sun,
        bgColor: COLORS.PROD,
        backgroundColor: "rgb(242, 125, 0)",
        borderWidth: 0,
      },
      {
        key: 4,
        label: "Prédiction autoconsommation directe",
        data: pConsoFromProdForecast,
        image: Sun,
        darkImage: Sun,
        bgColor: COLORS.PROD,
        backgroundColor: "rgb(242, 125, 0, 0.5)",
        borderWidth: 0,
        isForecast: true
      },
      {
        key: 5,
        label: "Décharge batterie",
        image: BatteryDown,
        darkImage: BatteryDown,
        data: pConsoFromBat,
        bgColor: COLORS.BATTERYDOWN,
        backgroundColor: "rgb(16, 173, 119)",
        borderWidth: 0,
      },
      {
        key: 5,
        label: "Prédiction décharge batterie",
        image: BatteryDown,
        darkImage: BatteryDown,
        data: pConsoFromBatForecast,
        bgColor: COLORS.BATTERYDOWN,
        backgroundColor: "rgb(16, 173, 119, 0.5)",
        borderWidth: 0,
        isForecast: true
      },
      {
        key: 6,
        label: "Consommation réseau",
        image: Pylon,
        darkImage: Pylon,
        data: pConsoFromGrid,
        bgColor: COLORS.CONSO,
        backgroundColor: "rgb(224, 0, 18)",
        borderWidth: 0,
      },
      {
        key: 6,
        label: "Prédiction consommation réseau",
        image: Pylon,
        darkImage: Pylon,
        data: pConsoFromGridForecast,
        bgColor: COLORS.CONSO,
        backgroundColor: "rgb(224, 0, 18, 0.5)",
        borderWidth: 0,
        isForecast: true
      },
    ],
  };
  return (
    <>
      <ResponsiveLayout>
        <div className="w-full  pb-64">
          <div className="container  w-full h-full flex flex-col items-center mx-auto pt-4 gap-2 md:gap-4 flex-col gap-2 hidden md:flex">
            <div className="w-1/2 flex">
              <Panel>
                <Switcher />
              </Panel>
            </div>
            {monitoringWithForecastIsError ||
              (manualMonitoringResponse[1] &&
                manualMonitoringResponse[1].isError) ? (
              <EmsAlert
                status="error"
                title={"Erreur lors de la récupération des données"}
                description={"Error"}
              ></EmsAlert>
            ) : (
              <div className="w-full">
                {monitoringWithForecastIsLoading ||
                  (manualMonitoringResponse[1] &&
                    manualMonitoringResponse[1].isLoading) ? (
                  <Skeleton className="w-full mx-2 my-4 md:mb-5 md:mt-4">
                    <div className=" w-full h-fullmd:w-[400px]"></div>
                  </Skeleton>
                ) : (
                  <Panel className="w-full">
                    <LabeledBarChart
                      data={graphConso}
                    />
                  </Panel>
                )}
              </div>
            )}
          </div>
          <Box className="flex md:hidden">
            {monitoringWithForecastIsError ||
              (manualMonitoringResponse[1] &&
                manualMonitoringResponse[1].isError) ? (
              <EmsAlert
                status="error"
                title={"Erreur lors de la récupération des données"}
                description={"Error"}
              ></EmsAlert>
            ) : (
              <div className="p-2 w-full h-full md:px-4 py-auto flex">
                <Skeleton
                  isLoaded={
                    !(
                      manualMonitoringResponse[1].isLoading ||
                      monitoringWithForecastIsLoading
                    )
                  }
                  className="flex my-auto w-full h-full"
                >
                  <Panel className="w-[100%] h-full min-w-full  md:h-full">
                    <Switcher units={["kWh", "Euro"]}>
                      <LabeledBarChart
                        data={graphConso}
                        displayCols
                      />
                    </Switcher>
                  </Panel>
                </Skeleton>
              </div>
            )}
          </Box>
        </div>
      </ResponsiveLayout>

    </>
  );
};

export default MySolarConsumptionPage;
