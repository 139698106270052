import React from 'react';
import '../assets/scss/pages/Lexicon.scss';

const LexiconPage: React.FC = () => {
    return (
        <div className="lexicon ">
            <h2 className="lexicon__h2">Un lexique pour mieux comprendre votre outil !</h2>

            <div className="lexicon__separator"></div>
            <h3 className="lexicon__h3">Autoconsommation directe (ACD)</h3>
            <p className="lexicon__text">Également appelé <b>Production directement consommée</b>, Il s&apos;agit de l&apos;électricité générée par les panneaux solaires qui est utilisée instantanément dans le système domestique sans passer par la batterie ou le réseau électrique.</p>

            <h3 className="lexicon__h3">Autoconsommation batterie (ACB)</h3>
            <p className="lexicon__text">Cela fait référence à l&apos;électricité stockée dans la batterie à partir de la production solaire excédentaire. Cette énergie est ensuite utilisée lorsque la production solaire directe est insuffisante pour répondre à la demande de consommation. Dans le bilan énergétique, elle correspond à la <b>décharge batterie</b>.</p>

            <h3 className="lexicon__h3">Surplus réinjecté</h3>
            <p className="lexicon__text">Lorsque la production solaire excède la demande de consommation et que la batterie est déjà complètement chargée, l&apos;excédent d&apos;électricité est réinjecté dans le réseau électrique. Cela permet de contribuer à la production d&apos;énergie renouvelable globale et peut générer des crédits ou des revenus en fonction des politiques locales.</p>

            <h3 className="lexicon__h3">Consommation réseau</h3>
            <p className="lexicon__text">Il s&apos;agit de l&apos;électricité provenant du réseau électrique conventionnel qui est utilisée lorsque la production solaire est insuffisante et que la batterie est déchargée. La consommation réseau intervient pour combler le déficit d&apos;énergie nécessaire à la demande des utilisateurs.</p>
        </div>
    );
}

export default LexiconPage;