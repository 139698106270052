import { URIs } from "../utils/toolbox";
import { CrudUri } from "../components/CrudUri";
import { RadioForm } from "../components/RadioForm";
import { FormControl } from "@chakra-ui/react";
import React, { startTransition, useState } from "react";

export const EnergyProviderAdminPage :React.FC = () =>{
    const [selectedItem, setSelectedItem] = useState(URIs.FluxTopologiesURI.toString());

    return <div className="max-w-screen">
    <FormControl className="control">
      <RadioForm
      value={selectedItem}
        options={[
          {
            value: URIs.FluxTopologiesURI,
            viewValue: "Flux Topology",
          },

          {
            value: URIs.EnergyProvidersUri,
            viewValue: "Energy Provider",
          },

          {
            value: URIs.ContractsURI,
            viewValue: "Contracts",
          } 
        ]}
        onChange={(v) => {
          startTransition(() => {
          setSelectedItem(v);
        });
        }}
        defaultValue={selectedItem}
      ></RadioForm>
    </FormControl>
    {<CrudUri uri={selectedItem} />}
  </div>
}