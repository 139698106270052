import {  createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export type AuthRequestType = {
  username: string;
  password: string; 
};
export type AuthResponseType = {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  token_type: string;
  scope: string;
};


export type CheckTokenRequestType = {
  token: string;
  client_id: string;
  client_secret : string;
  [key: string]: string | number;
};
export type CheckTokenResponseType = {
 active : boolean
};

export type RefreshTokenRequestType = { 
  refreshToken:string
};
export type RefreshTokenResponseType = {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  token_type: string;
  scope: string;
};

export type LogoutRequestType = {
  refresh_token: string; 
};
export type LogoutResponseType = {
 active : boolean
};
const BASE_URLEMS_AUTH =  `${process.env.REACT_APP_API_URL}/api/${process.env.REACT_APP_API_VERSION}/auth/`;
export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URLEMS_AUTH,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    prepareHeaders: (headers: any) => {
      headers.set("Accept", "application/json");
      return headers;
    }, 
  }),
  endpoints: (builder) => ({
    login: builder.mutation<AuthResponseType, AuthRequestType>({
      query: (auth) => ({
        url: `login`,
        method: "POST",  
        body:  auth,
      }),
      
    }),
    checkToken : builder.mutation<CheckTokenResponseType, CheckTokenRequestType>({
      query: (auth) => ({
        url: `protocol/openid-connect/token/introspect`,
        method: "POST",
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        prepareHeaders: (headers: any) => {
          headers.set(
            "Content-Type",
            "application/x-www-form-urlencoded"
          );
          return headers;
        },
        body: (() => {
          const formBody = [];
          for (const property in auth) {
            const encodedKey = encodeURIComponent(property);
            const encodedValue = encodeURIComponent(auth[property]);
            formBody.push(encodedKey + "=" + encodedValue);
          }

          return formBody.join("&");
        })(),
      }),
    }),
    refreshToken : builder.mutation<RefreshTokenResponseType, RefreshTokenRequestType>({
      query: (auth) => ({
        url:  `refreshToken`,
        method: "POST", 
        body:  auth,
      }),
    }),
    logout:  builder.mutation<LogoutResponseType, LogoutRequestType>({
      query: (auth) => ({
        url: `logout`,
        method: "POST", 
        body: auth,
      }),
    }),
  }),
});
 
export const { useLoginMutation ,useCheckTokenMutation,useLogoutMutation,useRefreshTokenMutation} = authApi;
