import { Grid, GridItem, Icon } from "@chakra-ui/react";

import { ReactComponent as GreenHouse } from "../assets/img/coaching/maison.svg";
import { ReactComponent as DPEWithFlag } from "../assets/img/coaching/dpe-with-flag.svg";
import { ReactComponent as GESWithFlag } from "../assets/img/coaching/ges-with-flag.svg";
import DpeGesSVG from "./DpeGesSVG";
import React from "react";
interface DPEGESProps {
  dpeGes: string;
  myDpeGes: string;
  type: string;
}
export const DPEGES: React.FC<DPEGESProps> = ({ dpeGes, myDpeGes, type }) => {
  return (
    <div className="flex flex-col gap-2">
      <Grid templateColumns="repeat(7, 1fr)">
        {["A", "B", "C", "D", "E", "F", "G"].map((classe, index) => {
          return (
            <GridItem key={index} w="100%" className="min-h-fit">
              <DpeGesSVG classe={classe} type={type} />
            </GridItem>
          );
        })}
      </Grid>
      <Grid
        templateColumns="repeat(7, 1fr)"
        columnGap={4}
        rounded={"md"}
        bg="#dadee6"
      >
        {["A", "B", "C", "D", "E", "F", "G"].map((classe, index) => {
          return (
            <GridItem
              key={index}
              w="100%"
              h="10"
              className="flex flex-col justify-center items-center p-2"
            >
              {classe == myDpeGes && (
                <Icon
                  as={type == "GES" ? GESWithFlag : DPEWithFlag}
                  boxSize={8}
                />
              )}
            </GridItem>
          );
        })}
      </Grid>
      <Grid
        templateColumns="repeat(7, 1fr)"
        columnGap={4}
        rounded={"md"}
        bg="#dadee6"
      >
        {["A", "B", "C", "D", "E", "F", "G"].map((classe, index) => {
          return (
            <GridItem
              key={index}
              w="100%"
              h="10"
              className="flex flex-col justify-center items-center  p-2"
            >
              {classe == dpeGes && <Icon as={GreenHouse} boxSize={8} />}
            </GridItem>
          );
        })}
      </Grid>
    </div>
  );
};
