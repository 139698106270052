import { getSelectedTopo } from "../reducers/topologyReducer";
import { emsApi, MeasuredData, MonitoringResponse, MonitoringWithForecastWithWeatherDataByHour } from "../services/ems.service";
import { WeatherItem } from "./WeatherItem";
import { store } from "../store/config";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { COLORS, TimeLabels, getFormatedDatetime } from "../utils/toolbox";
import "../assets/scss/components/WeatherDetails.scss";
import { Skeleton } from "@chakra-ui/skeleton";
import moment from "moment";
import EmsAlert from "./EmsAlert";

//legendes images
import { ReactComponent as Sun } from "../assets/icons/ifpen-sun.svg";
import { ReactComponent as Pylon } from "../assets/icons/ifpen-pylon.svg";
import { ReactComponent as BatteryUp } from "../assets/icons/ifpen-battery-up.svg";
import { ReactComponent as BatteryDown } from "../assets/icons/ifpen-battery-down.svg";
import { Switcher } from "./Switcher";
import { useSwitcherContext } from "../contexts/SwitcherContext";
export interface WeatherItemType {
  temperature: number;
  unit: string;
  iconId: string;
  weatherIconUrl: string;
  description: string;
  detailsDescription: string;
}
interface DataSet {
  key: number;
  label: string;
  image: unknown;
  darkImage: unknown
  bgColor: COLORS;
  data: string[];
  backgroundColor: string;
  isForecast?: boolean
}
export interface DataHour {
  hour: string;
  labels: string[];
  weather: WeatherItemType;
  datasets: DataSet[];
}

export const WeatherDetails: React.FC = () => {
  const navigate = useNavigate();
  const [maxProd, setMaxProd] = useState(0);
  const [monitoringData, setMonitoringData] = useState<MonitoringWithForecastWithWeatherDataByHour>();
  const [dataByHours, SetDataByHours] = useState<DataHour[]>([]);
  const topologyId = getSelectedTopo(store.getState()).id;
  const { useLazyGetMonitoringWithForecastWithWeatherDatabyHoursQuery } =
    emsApi;
  const [
    getMonitoringWithForecastWithWeatherDatabyHours,
    { isError: getMonitoringDataIsError },
  ] = useLazyGetMonitoringWithForecastWithWeatherDatabyHoursQuery();
  const { switcherDate, setSwitcherDate } =
    useSwitcherContext();
  //refresh data every minute
  useEffect(() => {
    if (moment(switcherDate).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')) {
      let timer: NodeJS.Timeout;
      const timout = setTimeout(() => {
        const date = moment().add(1, "hour");
        const roundedMinutes = Math.round(date.minutes() / 10) * 10;
        const startDateForecast = date.minutes(roundedMinutes).toDate();
        setSwitcherDate(startDateForecast);
        timer = setInterval(() => {
          const date = moment().add(1, "hour");
          const roundedMinutes = Math.round(date.minutes() / 10) * 10;
          const startDateForecast = date.minutes(roundedMinutes).toDate();
          setSwitcherDate(startDateForecast);
        }, 60000);
      }, (60 - new Date().getSeconds()) * 1000);
      return () => {
        window.clearInterval(timout);
        window.clearInterval(timer);
      };
    }
  }, [switcherDate]);
  useEffect(() => {
    if (topologyId == "") {
      navigate("/redirect");
    }
  }, [topologyId]);

  useEffect(() => {
    const startDate = new Date();
    startDate.setUTCHours(0, 0, 0, 0);
    getMonitoringWithForecastWithWeatherDatabyHours({
      date: moment(switcherDate).format('YYYY-MM-DD'),
      topologyId,
      aggregation: "1h",
    })
      .unwrap()
      .then(
        (res) => {
          setMonitoringData(res.data);
          setMaxProd(res.totalMax);
        },
        (error) => {
          console.log(error);
        }
      );
  }, [topologyId, switcherDate]);

  useEffect(() => {
    const buildDataForHour = (
      actual: MonitoringResponse,
      forecast: MonitoringResponse,
      hour: string,
      weather: WeatherItemType
    ) => {
      const labels: TimeLabels[] = [];
      const pProdConsByConsumers: string[] = [];
      const pProdConsByBat: string[] = [];
      const pProdSentToGrid: string[] = [];


      const pProdConsByBatForecast: string[] = [];
      const pProdSentToGridForecast: string[] = [];
      const pProdConsByConsumersForecast: string[] = [];

      const unit = "kWh";

      //Actual
      const actualDayData = actual["day"]
      const filterDayTime = (item: MeasuredData) =>
        actualDayData["aggregation"] == "1h" ? item.dayTime : true;
      (actualDayData["pProdConsByBat" + unit] as MeasuredData[])
        .filter(filterDayTime)
        .forEach((item: MeasuredData) => {
          getFormatedDatetime(item, "day", labels);
          pProdConsByBat.push(item.energy.toFixed(1));
        });

      (actualDayData["pProdConsByConsumers" + unit] as MeasuredData[])
        .filter(filterDayTime)
        .forEach((item: MeasuredData) => {
          pProdConsByConsumers.push(item.energy.toFixed(1));
        });

      (actualDayData["pProdSentToGrid" + unit] as MeasuredData[])
        .filter(filterDayTime)
        .forEach((item: MeasuredData) => {
          pProdSentToGrid.push(item.energy.toFixed(1));
        });
      //forecast
      const forecastDayData = forecast["day"]
      const filterDayTimeForecast = (item: MeasuredData) =>
        forecastDayData["aggregation"] == "1h" ? item.dayTime : true;
      (forecastDayData["pProdConsByBat" + unit] as MeasuredData[])
        .filter(filterDayTimeForecast)
        .forEach((item: MeasuredData) => {
          getFormatedDatetime(item, "day", labels);
          pProdConsByBatForecast.push(item.energy.toFixed(1));
        });

      (forecastDayData["pProdConsByConsumers" + unit] as MeasuredData[])
        .filter(filterDayTimeForecast)
        .forEach((item: MeasuredData) => {
          pProdConsByConsumersForecast.push(item.energy.toFixed(1));
        });

      (forecastDayData["pProdSentToGrid" + unit] as MeasuredData[])
        .filter(filterDayTimeForecast)
        .forEach((item: MeasuredData) => {
          pProdSentToGridForecast.push(item.energy.toFixed(1));
        });

      const graphProd: DataHour = {
        hour: hour,
        labels: labels.map((label) => label.label),
        weather: weather,
        datasets: [{
          key: 1,
          label: "Autoconsommation directe",
          data: pProdConsByConsumers,
          image: Sun,
          darkImage: Sun,
          bgColor: COLORS.PROD,
          backgroundColor: "rgb(242, 125, 0)",
        },
        {
          key: 1,
          label: "Prédiction autoconsommation directe",
          data: pProdConsByConsumersForecast,
          image: Sun,
          darkImage: Sun,
          bgColor: COLORS.PROD,
          backgroundColor: "rgb(242, 125, 0,0.5)",
          isForecast: true,
        },
        {
          key: 2,
          label: "Charge batterie",
          image: BatteryUp,
          darkImage: BatteryUp,
          data: pProdConsByBat,
          bgColor: COLORS.BATTERYUP,
          backgroundColor: "rgb(66, 216, 40)",
        },
        {
          key: 2,
          label: "Prédiction charge batterie",
          image: BatteryUp,
          darkImage: BatteryUp,
          data: pProdConsByBatForecast,
          bgColor: COLORS.BATTERYDOWN,
          backgroundColor: "rgb(66, 216, 40,0.5)",
          isForecast: true,
        },
        {
          key: 3,
          label: "Surplus réinjecté dans le réseau",
          image: Pylon,
          darkImage: BatteryDown,
          data: pProdSentToGrid,
          bgColor: COLORS.SURPLUS,
          backgroundColor: "rgb(224, 47, 239)",
        },
        {
          key: 3,
          label: "Prédiction surplus réinjecté dans le réseau",
          image: Pylon,
          darkImage: Pylon,
          data: pProdSentToGridForecast,
          bgColor: COLORS.SURPLUS,
          backgroundColor: "rgb(224, 47, 239,0.5)",
          isForecast: true,
        },
        ],
      };
      return graphProd;
    };

    SetDataByHours(() => []);
    if (monitoringData) {
      Object.keys(monitoringData)
        .sort((s1, s2) => new Date(s1).getTime() - new Date(s2).getTime())
        .forEach((hour) => {
          const data4Hour: DataHour = buildDataForHour(
            monitoringData[hour].data.outputActual,
            monitoringData[hour].data.outputForecasted,
            hour,
            monitoringData[hour].weather
          );
          SetDataByHours((prev) => [...prev, data4Hour]);
        });
    }
  }, [monitoringData]);
  return getMonitoringDataIsError ? (
    <div className="mt-2">
      <EmsAlert
        status="error"
        title="une erreur s'est produite"
        description=""
      />
    </div>
  ) : (
    <div className="py-16 ">
      <Switcher withUnits={false} withPeriods={false} className="w-full px-2 md:w-1/2 mx-auto"
        customStyleInput={
          {
            color: "white",
            backgroundColor: COLORS.EMS_BLUE,
            paddingLeft: "14px",
            paddingRight: "14px"
          }
        }
        customStyleChevronLeftIcon={{
          backgroundColor: "white",
          borderRadius: "full"
        }}
        customStyleChevronRightIcon={{
          backgroundColor: "white"
        }}
      ></Switcher>
      <div
        className="list-wheater w-full flex flex-col gap-2 md:mx-auto md:px-4 h-[700px] overflow-hidden overflow-y-scroll"
      >
        {dataByHours.map((data4Hour: DataHour, index: number) => {
          return (
            <Skeleton key={index} isLoaded={monitoringData != undefined}>
              <WeatherItem
                maxProd={maxProd || 50}
                key={index}
                data={data4Hour}
              />
            </Skeleton>
          );
        })}
      </div>
    </div>
  );
};
