import { Bar } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";

import { Chart, registerables, TooltipItem, TooltipModel } from "chart.js";
import { COLORS, currentTimeString, displayWeatherIcon, formatDateHoursAndMinutes } from "../utils/toolbox";
import { DataHour } from "./WeatherDetails";
import { Button, Image } from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { ChartOptions } from "chart.js";
Chart.register(...registerables);
Chart.register(annotationPlugin);
interface WeatherItemProps {
  data: DataHour;
  maxProd: number;
}

export const WeatherItem: React.FC<WeatherItemProps> = ({
  data,
  maxProd,
}) => {
  const options: ChartOptions<"bar"> = {
    indexAxis: "y",
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        max: maxProd,
        display: false,
        reverse: true,
        border: {
          display: false,
        },
        stacked: true,
        beginAtZero: true,
        ticks: {
          color: "#0487FB",
          font: {
            family: '"Titillium Web Regular"',
          },
        },
        grid: {
          display: false,
        },
      },

      y: {
        display: false,
        position: "right",
        border: {
          display: false,
        },
        grid: {
          display: false,
          color: "#0487FB",
        },
        beginAtZero: true,
        stacked: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        enabled: true,
        mode: "nearest",
        callbacks: {
          label: function (this: TooltipModel<'bar'>, tooltipItem: TooltipItem<'bar'>): string {
            return tooltipItem.formattedValue;
          },
        },
      },

    },
  };

  return (
    <div className="w-full border-l-4 border-l-red-700  max-h-fit flex flex-row justify-between items-center border  bg-white bg-opacity-50"
    >
      <div className="flex flex-row h-full items-center   gap-2 md:gap-4  w-full">
        <p className="font-bold h-full flex justify-start items-center px-2 max-w-fit gap-4">
          {formatDateHoursAndMinutes(moment(new Date(data.hour)).toDate())}
        </p>
        <div className="flex h-full justify-start items-center px-2 min-w-fit">
          {data.weather && data.weather.weatherIconUrl && <div>
            <Image src={displayWeatherIcon(data.weather.iconId) || data.weather.weatherIconUrl} w={"12"} h={"12"}
              title={data.weather.detailsDescription ? data.weather.detailsDescription : data.weather.description}
            />
          </div>}
        </div>
        <p className="font-bold h-full flex justify-start items-center px-2 max-w-fit px-2 min-w-fit mx-2">
          {data.weather && data.weather.unit &&
            data.weather.unit.toLowerCase() == "k" &&
            (data.weather.temperature - 273.15).toFixed(0) + " °"}
        </p>
        {moment(new Date(data.hour)).minutes(0).seconds(0).milliseconds(0).isSame(moment(new Date()).minutes(0).seconds(0).milliseconds(0))
          && <div className="flex items-center mx-auto" >
            <Button backgroundColor={COLORS.EMS_BLUE} color={"white"} style={{ padding: "8px" }} variant={"unstyled"} _hover={{}}>{currentTimeString()}</Button>
          </div>}
      </div>
      <div className="flex flex-row justify-end gap-2 w-full">
        <div className="flex-none font-bold h-full flex justify-start items-center px-2 max-w-fit ">
        </div>
        <div className="h-20 flex max-w-fit w-full flex-none">
          <Bar data={data} options={options} className="relative" />
        </div>
      </div>
    </div>
  );
};
