import { COLORS } from "../utils/toolbox";
import { Card, Icon, Skeleton } from "@chakra-ui/react";
import { ReactComponent as BatteryUp } from "../assets/icons/ifpen-battery-up.svg";
import { ReactComponent as BatteryDown } from "../assets/icons/ifpen-battery-down.svg";
import { ReactComponent as Info } from "../assets/icons/Info.svg";
import React, { ReactNode, memo } from "react";
interface BatteryIndicatorsProps {
  charge: string;
  decharge: string;
  nextCharge: string;
  isLoaded: boolean;
}
export const BatteryIndicators: React.FC<BatteryIndicatorsProps> = ({
  charge,
  decharge,
  nextCharge,
  isLoaded,
}) => {
  const indicators: BatteryIndicatorProps[] = [
    {
      icon: BatteryUp,
      color: COLORS.BATTERYUP,
      isLoaded: isLoaded,
      text: <p>Capacité charge</p>,
      value: charge,
    },
    {
      icon: BatteryDown,
      color: COLORS.BATTERYDOWN,
      isLoaded: isLoaded,
      text: <p>Capacité décharge</p>,
      value: decharge,
    },
    {
      icon: Info,
      color: COLORS.EMS_BLUE,
      isLoaded: isLoaded,
      text: (
        <p>
          Prochaine charge{" "}
          <span
            style={{
              color: COLORS.EMS_BLUE_LIGHT,
            }}
          >
            {" "}
            Dans {nextCharge}
          </span>
        </p>
      ),
      value: "Infos",
    },
  ];
  return (
    <div className="flex gap-2 md:gap-4 md:gap-4 justify-between md:justify-center  py-2 px-2">
      {indicators.map((ind, index) => {
        return (
          <BatteryIndicator
            key={index}
            color={ind.color}
            icon={ind.icon}
            isLoaded={ind.isLoaded}
            text={ind.text}
            value={ind.value}
          />
        );
      })}
    </div>
  );
};

interface BatteryIndicatorProps {
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  text: ReactNode;
  value: string;
  color: COLORS;
  isLoaded: boolean;
}
const BatteryIndicatorComponent:React.FC<BatteryIndicatorProps> = ({ icon, color, value, text, isLoaded }) => {
  return (
    <Skeleton isLoaded={isLoaded} className="w-full h-full  py-2 max-w-fit ">
      <Card className="w-full h-full p-4">
        <div
          className={`md:w-30  flex flex-col justify-between gap-2 px-auto py-auto h-full`}
          style={{
            color: color,
          }}
        >
          <div className="flex justify-center items-center ">
            <Icon as={icon} boxSize={6} fill={color} />
          </div>
          <div className="flex flex-wrap text-center justify-center items-center font-semibold px-2">
            {text}
          </div>
          <div
            className={`flex flex-row justify-center items-center rounded-lg text-white font-bold max-w-fit py-3 px-4 mx-auto`}
            style={{
              backgroundColor: color,
            }}
          >
            {value}
          </div>
        </div>
      </Card>
    </Skeleton>
  );
}
const BatteryIndicator = memo(BatteryIndicatorComponent)