import React from "react";
import { COLORS } from "../utils/toolbox";

interface LabelWithValueProps {
  value: string;
  color: COLORS;
  label: string;
}
export const LabelWithValue: React.FC<LabelWithValueProps> = ({
  value,
  color,
  label,
}) => {
  return (
    <p className="my-2 text-[#003265]">
      <span
        className="mx-2 rounded-full text-center my-5 px-3 py-1 text-white font-bold"
        style={{ backgroundColor: color }}
      >
        {value}
      </span>
      {label}
    </p>
  );
};
