import { ROLES, getUserRoles } from "../utils/toolbox"; 
import { useEffect, useState } from "react";
import { selectAuth } from "../reducers/authReducer";
import { store } from "../store/config"; 
 const  useGetMembership = ()=>{ 
    const [membership,setMembership] = useState<string []>(["UNKNOWN"])
    const auth = selectAuth(store.getState())
    const userRoles = getUserRoles(auth.user?.token || "")
    useEffect(()=>{
        if(auth && userRoles){
            if(userRoles.some(r=>r == ROLES.WEBAPP_USER_SOALIS)  && userRoles.some(r=>r == ROLES.WEBAPP_USER_FRACTAL) ) setMembership(()=>[ "SOALIS","FRACTAL"])   ;
            else if(userRoles.some(r=>r == ROLES.WEBAPP_USER_SOALIS)) setMembership(["SOALIS"]) 
            else if(userRoles.some(r=>r == ROLES.WEBAPP_USER_FRACTAL)) setMembership(["FRACTAL"]) 
        }
    },[])
    return  membership
}  
export default useGetMembership