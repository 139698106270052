import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  IconButton,
  Image,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import React, { ReactElement } from "react";
import { MenuItemHorizontal } from "./MenuItemHorizontal";
import { COLORS } from "../utils/toolbox";
import { Link, useLocation } from "react-router-dom"; 
import Logo from "../assets/img/EMS-Light.svg";

interface MenuItemType {
  name: string;
  text: string;
  url: string;
  show: boolean;
  callback?: () => void;
  icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
}
type MenuHorizontalItemType = Pick<
  MenuItemType,
  "name" | "text" | "url" | "show" | "callback"
> & {
  allowedRoles: string[];
  iconReactElement?: ReactElement;
};
interface ResponsiveNavBarProps {
  menuItems: MenuHorizontalItemType[];
  tabItems: MenuItemType[];
  className: string;
}

export const ResponsiveNavBar: React.FC<ResponsiveNavBarProps> = ({
  menuItems,
  tabItems,
  className,
}) => {
  const { isOpen } = useDisclosure();
  const {
    isOpen: isOpenDrawer,
    onOpen: onOpenDrawer,
    onClose: onCloseDrawer,
  } = useDisclosure();

  const location = useLocation();
  const { pathname } = location;
  return (
    <Box
      id="navbar"
      className={` ${className} pb-2 md:pb-none`}
      insetX={"0px"}
      bottom="0"
      w={"full"}
      style={{ backgroundColor: COLORS.EMS_BLUE }}
    >
      <Flex
        flexDirection={"row"}
        w={"full"}
        flexFlow={"row"}
        alignContent={"center"}
        paddingRight={{ sm: "2px", md: "10px" }}
        justifyContent={"space-between"}
      >
        <div className="my-auto flex-none">
          <Link to={"/redirect"} className="ml-0 my-auto flex-none">
            <Image className="app-bar__image" src={Logo} alt="Logo EMS" />
          </Link>
        </div>
        {tabItems.map((tab, index) => {
          return tab.show ? (
            <div className="flex  my-auto  md:p-1 max-h-fit" key={index}>
              <Link to={tab.url}>
                <VStack
                  spacing={{
                    sm: 2,
                    md: 1,
                  }}
                >
                  <IconButton
                    aria-label={tab.text}
                    color={pathname == tab.url ? "red" : "white"}
                    size={"sm"}
                    icon={
                      <Icon
                        as={tab.icon}
                        width={"full"}
                        height={"full"}
                        color={pathname == tab.url ? "red.500" : "white"}
                        boxSize={"full"}
                      />
                    }
                    variant="unstyled"
                  />
                  <Text
                    fontSize="xs"
                    color={pathname == tab.url ? "red.500" : "white"}
                  >
                    {tab.text}
                  </Text>
                </VStack>
              </Link>
            </div>
          ) : (
            <></>
          );
        })}
        <Drawer
          placement={"right"}
          colorScheme={COLORS.EMS_BLUE}
          onClose={onCloseDrawer}
          isOpen={isOpenDrawer}
        >
          <DrawerOverlay />
          <DrawerContent bgColor={COLORS.EMS_BLUE}>
            <DrawerCloseButton color={"white"} />
            <DrawerHeader></DrawerHeader>
            <DrawerBody
              color={COLORS.EMS_BLUE}
              className="flex flex-col gap-4 w-full"
            >
              {menuItems.filter(item=>item.show).map((item, index) =>   <MenuItemHorizontal
                    key={index}
                    icon={item.iconReactElement}
                    link={item.url}
                    text={item.text}
                    callback={() => {
                      if (item.callback) {
                        item.callback();
                      }
                      onCloseDrawer();
                    }}
                    allowedRoles={item.allowedRoles}
                  /> 
              )}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        <div className="my-auto pr-[8px] flex justify-start my-auto p-2">
          <IconButton
            aria-label="Menu"
            colorScheme={COLORS.EMS_BLUE}
            onClick={onOpenDrawer}
          >
            {!isOpen ? (
              <>
                <VStack spacing={2}>
                  <HamburgerIcon boxSize={8} />
                </VStack>
              </>
            ) : (
              <CloseIcon boxSize={6} />
            )}
          </IconButton>
        </div>
      </Flex>
    </Box>
  );
};
