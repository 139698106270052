import { emsApi } from "../services/ems.service";
import { Crud } from "../components/Crud";
import { topologyApi } from "../services/topology.service";
import {
  ClassNames,
  ENTITIES,
  PATH_PREFIX_FLUXTOPOLOGY,
  URIs,
} from "../utils/toolbox";
import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import { Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { EnumSelect } from "../components/EnumSelect";

export const ParametragePage: React.FC = () => {
  const { useLazyGetEnumsListQuery } = emsApi;
  const [
    getEnumsList,
    {
      data: enumsList,
      isError: listEnumsIsError,
      error: listEnumsError,
      isLoading: listEnumsLoading,
    },
  ] = useLazyGetEnumsListQuery();
  const { useCreateMutation } = topologyApi;
  const [create,] = useCreateMutation();
  const schema = Yup.object().shape({
    name: Yup.string().required("Ce champ est obligatoire"),
    viewName: Yup.string().required("Ce champ est obligatoire"),
    className: Yup.string().required("Ce champ est obligatoire"),
    typeField: Yup.string().required("Ce champ est obligatoire"),
    show: Yup.boolean().required("Ce champ est obligatoire"),
    ordre: Yup.number().required("Ce champ est obligatoire"),
    //value : Yup.string().required("Ce champ est obligatoire"),
  });
  return (
    <>
      <div>
        {
          <Formik
            validationSchema={schema}
            initialValues={{
              name: "",
              viewName: "",
              className: "",
              typeField: "",
              value: "",
              ordre: 1,
              show: true,
            }}
            onSubmit={(values) => {
              create({
                uri: "parametrage",
                body: {
                  ...values,
                  className: values["className"],
                  show: values["show"] ? 1 : 0,
                },
              })
                .then(() => {
                  alert("Field created");
                })
                .catch((err) => {
                  console.log(err);
                });
            }}
          >
            {({
              handleSubmit,
              errors,
              values,
              setFieldValue,
            }) => (
              <form
                // onSubmit={handleSubmit}
                className="w-full md:w-1/2 pl-2 flex flex-col gap-4"
              >
                <h1 className="">
                  <p className="font-extrabold my-4">
                    {" "}
                    Paramètrage des champs{" "}
                  </p>
                </h1>

                <FormControl className="flex ms:flex-row items-center">
                  <FormLabel className="min-w-fit">
                    {" "}
                    <p className="font-extrabold"> Nom </p>
                  </FormLabel>
                  <div className="flex flex-col w-full">
                    <Input
                      type="text"
                      name="name"
                      value={values["name"]}
                      required
                      onChange={(e) => setFieldValue("name", e.target.value)}
                    />
                    {errors && errors["name"] && (
                      <div className="text-red-500">{errors["name"]}</div>
                    )}
                  </div>
                </FormControl>
                <FormControl className="flex ms:flex-row items-center">
                  <FormLabel className="min-w-fit">
                    {" "}
                    <p className="font-extrabold"> Nom d&apos;affichage </p>
                  </FormLabel>
                  <div className="flex flex-col w-full">
                    <Input
                      type="text"
                      name="viewName"
                      value={values["viewName"]}
                      required
                      onChange={(e) =>
                        setFieldValue("viewName", e.target.value)
                      }
                    />
                    {errors && errors["viewName"] && (
                      <div className="text-red-500">{errors["viewName"]}</div>
                    )}
                  </div>
                </FormControl>
                <FormControl className="flex ms:flex-row items-center">
                  <FormLabel className="min-w-fit">
                    {" "}
                    <p className="font-extrabold">
                      {" "}
                      Nom de la classe du champ{" "}
                    </p>
                  </FormLabel>
                  <div className="flex flex-col w-full">
                    <EnumSelect
                      name={"classnames"}
                      defaultValue=""
                      onChange={(e) =>
                        setFieldValue("className", e.target.value)
                      }
                      selected={undefined}
                    />

                    {errors && errors["className"] && (
                      <div className="text-red-500">{errors["className"]}</div>
                    )}
                  </div>
                </FormControl>
                <FormControl className="flex ms:flex-row items-center ">
                  <FormLabel className="min-w-fit">
                    <p className="font-extrabold"> Type du champ </p>
                  </FormLabel>
                  <div className="flex flex-col w-full">
                    <EnumSelect
                      name="typefield"
                      defaultValue="AUTRE"
                      onChange={(e) => {
                        setFieldValue("typeField", e.target.value);
                        if (e.target.value == "ENUM") {
                          getEnumsList();
                        }
                        if (e.target.value == "CLASS")
                          setFieldValue("value", ENTITIES[0]);
                        if (e.target.value == "DATE")
                          setFieldValue("value", "");
                        if (e.target.value == "AUTRE")
                          setFieldValue("value", "");
                      }}
                      selected={undefined}
                    />

                    {errors && errors["typeField"] && (
                      <div className="text-red-500">
                        {errors["typeField"]}
                        {JSON.stringify(errors)}
                      </div>
                    )}
                  </div>
                </FormControl>

                {values["typeField"] == "AUTRE" ||
                values["typeField"] == "BOOLEAN" ||
                values["typeField"] == "INTEGER" ||
                values["typeField"] == "DOUBLE" ? (
                  <FormControl className="flex ms:flex-row items-center">
                    <FormLabel className="min-w-fit">
                      {" "}
                      <p className="font-extrabold"> Valeur </p>{" "}
                    </FormLabel>
                    <div className="flex flex-col w-full">
                      <Input
                        type="text"
                        name="value"
                        value={values["value"]}
                        onChange={(e) => setFieldValue("value", e.target.value)}
                      />
                    </div>
                  </FormControl>
                ) : values["typeField"] == "ENUM" ? (
                  <div className="flex flex-col w-full">
                    {JSON.stringify(listEnumsError)}
                    {listEnumsIsError ? (
                      <div>Error </div>
                    ) : listEnumsLoading ? (
                      <div>Loading </div>
                    ) : (
                      <FormControl className="flex ms:flex-row items-center">
                        <FormLabel className="min-w-fit">
                          {" "}
                          <p className="font-extrabold"> Valeur </p>{" "}
                        </FormLabel>
                        <Select
                          name="value"
                          placeholder="Select option"
                          required
                          value={values["value"]}
                          onChange={(e) =>
                            setFieldValue("value", e.target.value)
                          }
                        >
                          {enumsList &&
                            enumsList.map((elt, index) => {
                              return (
                                <option key={index} value={elt}>
                                  {elt}
                                </option>
                              );
                            })}
                        </Select>
                      </FormControl>
                    )}
                  </div>
                ) : values["typeField"] == "DATE" ? (
                  <div className="flex flex-col w-full">
                    <FormControl className="flex flex-row items-center">
                      <FormLabel className="min-w-fit">
                        <h1 className="min-w-fit font-extrabold flex flex-row items-center content-center">
                          Valeur{" "}
                          <span className="text-red-500 my-auto pl-2">*</span>
                        </h1>
                      </FormLabel>
                      <div className="flex flex-col w-full">
                        <Input
                          type={"datetime-local"}
                          name="value"
                          required
                          value={values["value"]}
                          onChange={(e) =>
                            setFieldValue("value", e.target.value)
                          }
                        />
                      </div>
                    </FormControl>
                  </div>
                ) : (
                  <div className="flex flex-col w-full">
                    <FormControl className="flex ms:flex-row items-center">
                      <FormLabel className="min-w-fit">
                        {" "}
                        <p className="font-extrabold"> Valeur </p>{" "}
                      </FormLabel>
                      <Select
                        name="value"
                        placeholder="Select option"
                        required
                        value={values["value"]}
                        onChange={(e) => setFieldValue("value", e.target.value)}
                      >
                        {ENTITIES.map((elt, index) => {
                          return (
                            <option
                              key={index}
                              value={"fr.ifpen.ems.site.domain." + elt}
                            >
                              {elt}
                            </option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                )}

                <div className="flex flex-col w-full">
                  <FormControl className="flex ms:flex-row items-center">
                    <FormLabel>
                      <p className="font-extrabold"> Ordre </p>
                    </FormLabel>
                    <Input
                      type="number"
                      value={values["ordre"]}
                      required
                      onChange={(e) => setFieldValue("ordre", e.target.value)}
                    />
                  </FormControl>
                  {errors && errors["ordre"] && (
                    <div className="text-red-500">{errors["ordre"]}</div>
                  )}
                </div>
                <div className="flex flex-col w-full">
                  <FormControl className="flex ms:flex-row items-center">
                    <FormLabel>
                      <p className="font-extrabold">
                        {" "}
                        Afficher dans le tableau et dans la modification ?{" "}
                      </p>
                    </FormLabel>
                    <Checkbox
                      defaultChecked
                      name="show"
                      isChecked={values["show"]}
                      // isChecked={values["show"]}
                      onChange={(e) => setFieldValue("show", e.target.checked)}
                    ></Checkbox>
                  </FormControl>
                </div>
                <Button
                  colorScheme="twitter"
                  className="rounded-md md:w-1/3 mx-auto"
                  size={"lg"}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Créer
                </Button>
              </form>
            )}
          </Formik>
        }
        <Crud
          uri={PATH_PREFIX_FLUXTOPOLOGY + "/" + URIs.ParametrageURI}
          title="Parametrage"
          obj={ClassNames.FIELD}
        />
      </div>
    </>
  );
};
