import {
  Box,
  HStack,
  UseRadioProps,
  useRadio,
  useRadioGroup,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";
interface RadioCardProps {
  radioProps: UseRadioProps;
  children: React.ReactNode;
  checkedBgColor?: string;
  borderColor?: string;
  status: "completed" | "started" | "error";
  px?: string;
  py?: string;
  className?: string;
}
export interface OptionType {
  value: string;
  viewValue: string | ReactNode;
  status?: "completed" | "started" | "error";
}
interface RadioFormProps {
  options: OptionType[];
  onChange: (value: string) => void;
  value: string;
  defaultValue?: string;
  checkedBgColor?: string;
  borderColor?: string;
  name?: string;
  px?: string;
  py?: string;
  className?: string;
}
const RadioCard: React.FC<RadioCardProps> = ({ children, radioProps, status, borderColor, checkedBgColor, className, px, py }) => {
  const { getInputProps, getRadioProps } = useRadio(radioProps);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        borderColor={borderColor}
        backgroundColor={status == "completed" ? "#289c41" : undefined}
        _checked={{
          bg: checkedBgColor ? checkedBgColor : "#003265",
          color: "white",
          borderColor: "teal.600",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={px || 5}
        py={py || 3}
        className={className}
      >
        {children}
      </Box>
    </Box>
  );
};

export const RadioForm: React.FC<RadioFormProps> = ({
  name,
  options,
  onChange,
  value,
  defaultValue,
  checkedBgColor,
  borderColor,
  px,
  py,
  className,
}) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: name + "",
    defaultValue: defaultValue,
    onChange: onChange,
  });

  const group = getRootProps();

  return (
    <HStack {...group}>
      {options.map((elt: OptionType) => {
        const radio = getRadioProps({ value: elt.value });
        return (
          <RadioCard
            key={elt.value}
            radioProps={{ ...radio, isChecked: elt.value == value }}
            checkedBgColor={checkedBgColor}
            borderColor={borderColor}
            status={elt.status ?? "started"}
            px={px}
            py={py}
            className={className}
          >
            {elt.viewValue}
          </RadioCard>
        );
      })}
    </HStack>
  );
};
