import React, { useEffect, useRef, useState } from "react";
import { Bar, getElementsAtEvent } from "react-chartjs-2";
import "../assets/scss/components/LabeledBarChart.scss";
import { TimeLabels } from "../utils/toolbox";
import { CircularDiv } from "./CircularDiv";

type LabeledBarChartProps = {
  data: any;
  displayCols?: boolean;
  getDataForLabel?: (label: TimeLabels) => void;
  className?: string;
};

/**
 * Vous pouvez utiliser le paramètre displayCols à True pour afficher les pictogrammes sous forme de colonne
 * de haut en bas avec leurs labels (utilisé par exemple sur la page /indicators).
 */
const LabeledBarChart: React.FC<LabeledBarChartProps> = ({
  data: initialData,
  displayCols = false,
  getDataForLabel,
  className,
}) => {
  const [data, setData] = useState(initialData);
  const chartRef = useRef<any>(null);

  const options: any = {
    maintainAspectRatio: false,
    responsive: true,
    type: "bar",
    scales: {
      x: {
        border: {
          display: false,
        },
        stacked: true,
        beginAtZero: true,
        ticks: {
          color: "#0487FB",
          font: {
            family: '"Titillium Web Regular"',
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        border: {
          display: false,
        },
        grid: {
          color: "#0487FB",
        },
        beginAtZero: true,
        stacked: true,
        ticks: {
          count: 10,
          callback: function (value: any, index: any, values: any) {
            return Math.round(value * 2) / 2;
          },
          color: "#0487FB",
          font: {
            family: '"Titillium Web Regular"',
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },

      datalabels: {
        display: false,
      },
      tooltip: {
        mode: "index",
      },
    },
  };
 

  useEffect(() => {
    //setData(removeZeroValues(initialData))

    if (initialData) {
      setData({
        ...initialData,
        labels: initialData.labels.map((x: TimeLabels) => (x ? x.label : "")),
      });
    }
    
  }, [initialData]);

  const handleLegendClick = (index: number) => {
    if (chartRef && chartRef.current) {
      const chartInstance = chartRef.current;

      if (chartInstance) {
        const { datasets } = chartInstance.data;
        datasets
          .filter((x: any) => x.key == index)
          .forEach((dataset: any) => {
            const visibility = dataset.hidden;
            dataset.hidden =
              visibility === null || visibility === undefined
                ? true
                : !visibility;
          });

        chartInstance.update();

        setData({ ...data, datasets: datasets });
      }
      console.log("handleLegendClickExecuted");
    }
  };

  return (
    <div className="labeled-bar-chart">
      {!displayCols && (
        <div className="labeled-bar-chart__legend">
          {data.datasets
            .filter((x: any) => !x.isForecast)
            .map((dataset: any, index: number) => (
              <div
                onClick={() => handleLegendClick(dataset.key)}
                className="labeled-bar-chart__legend__image"
              >
                <CircularDiv
                  color={dataset.hidden ? "grey" : dataset.bgColor}
                  icon={dataset.image}
                  forecastExist={data.datasets.some(
                    (x: any) => x.isForecast && x.isForecast === true
                  )}
                />
              </div>
            ))}
        </div>
      )}
      <div className="chart overflox-x-scroll w-full">
        <Bar
          className="chart"
          ref={chartRef}
          data={data}
          options={options}
          //onClick={zoom}
        />
      </div>
      {displayCols && (
        <div className="labeled-bar-chart__legend-col">
          {data.datasets
            .filter((x: any) => !x.isForecast)
            .map((dataset: any, index: number) => (
              <div key={index} className="labeled-bar-chart__legend-col-text">
                <div
                  onClick={() => handleLegendClick(dataset.key)}
                  className="labeled-bar-chart__legend-col__image"
                >
                  <CircularDiv
                    color={dataset.hidden ? "grey" : dataset.bgColor}
                    icon={dataset.image}
                    forecastExist={data.datasets.some(
                      (x: any) => x.isForecast && x.isForecast === true
                    )}
                  />
                </div>
                {dataset.label}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default LabeledBarChart;
