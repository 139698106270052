import { Select } from "@chakra-ui/react"
import { emsApi } from "../services/ems.service"
import React, { useEffect, useState } from "react"

interface EnumSelectProps {
    name : string,
    defaultValue : string,
    onChange : (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
    selected?: boolean | undefined 
}
export const EnumSelect : React.FC<EnumSelectProps>  = ({name,defaultValue,onChange}) =>{
    const [enumValues,setEnumValues] = useState<string []>([])
    const {useLazyGetEnumValuesQuery} = emsApi
    const [getEnumValuesByName,] = useLazyGetEnumValuesQuery()
    
    useEffect(()=>{
        getEnumValuesByName({name}).unwrap().then(res=>{
            setEnumValues(res)
          }).catch(err=>{
            console.log(err);
          })
    },[getEnumValuesByName,name])

    return <>
    <Select
    placeholder="Select option"
    defaultValue={
        defaultValue
    }
    onChange={onChange }
  >
    {enumValues && enumValues.map((v: string, index: number) => (
          <option
          key={index}
            selected={v===defaultValue
            }
            value={v}
          >
            {v}
          </option>
        ))}
  </Select>
    </> 
}