import { Icon, Image, Text } from "@chakra-ui/react";
import Tournesol15 from "../assets/img/tournesol15.svg";
import Tournesol25 from "../assets/img/tournesol25.svg";
import Tournesol35 from "../assets/img/tournesol35.svg";
import Tournesol45 from "../assets/img/tournesol45.svg";
import Tournesol55 from "../assets/img/tournesol55.svg";
import { ReactComponent as Leafwhite } from "../assets/img/leafwhite.svg";
import { COLORS } from "../utils/toolbox";
import React from "react";
interface TournesolProps {
  score: number;
}
export const Tournesol: React.FC<TournesolProps> = ({ score }) => {
  const icon =
    score == 1
      ? Tournesol15
      : score == 2
      ? Tournesol25
      : score == 3
      ? Tournesol35
      : score == 4
      ? Tournesol45
      : Tournesol55;

  return (
    <div className="relative h-[200px] md:h-[300px] md:h-full flex flex-col justify-end items-center min-w-[300px]">
      <div className="max-w-fit absolute top-0 right-0">
      <div className="uppercase text-white font-extrabold">
            ConsoScore
        </div>
        <Text
          color="#FFFFFF"
          background={COLORS.BATTERYUP}
          className="rounded-full max-w-fit m-auto text-center font-extrabold leading-tight mt-2  md:py-2 px-2 md:px-4 py-1 flex flex-row gap-2 md:gap-3"
        >
          <div className="flex flex-col justify-center">
            <Icon as={Leafwhite} color={"white"} boxSize={5} />
          </div>
          <div>
            <span className="font-extrabold text-3xl">{score}</span>{" "}
            <span className="">/5</span>
          </div>
        </Text>
      </div>
      <Image src={icon} className="h-full" />
    </div>
  );
};
