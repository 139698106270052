import { createApi } from '@reduxjs/toolkit/query/react'
import { ResponseType } from './topology.service';
import { baseQueryWithReauth } from './ems.service';

export type ParametrageRequestType = {
    className :string
}
export interface  Field extends ResponseType {
        id? :number;
        name : string ;
        viewName:string;
        className :string;
        typeField :string;
        show : number;
        value : string
}
const PARAMETRAGE_PATH="topologies"
export const parametrageApi = createApi({
    reducerPath: 'parametrageApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getFields: builder.query<Field[], ParametrageRequestType>({
        query: (param) => ({url : `${PARAMETRAGE_PATH}/getFields`,method:"POST",body : JSON.stringify( {
           className : param.className
        }) }),
        transformResponse: (rawResult:  Field[]) => { 
            return rawResult
          },
      }),
      createField: builder.mutation<Field, Field>({
        query: (param) => ({url : `${PARAMETRAGE_PATH}/createFields`,method:"POST",body : JSON.stringify( {...param} ) }),
      }),
  })
})

  export const {  useGetFieldsQuery,useLazyGetFieldsQuery,useCreateFieldMutation} = parametrageApi