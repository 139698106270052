import { useNavigate } from "react-router-dom";
import { COLORS } from "../utils/toolbox";
import { Button, HStack,Text } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import { Showforpermission } from "./Showforpermission";

interface MenuItemHorizontalProps {
  link: string;
  text: string;
  callback: () => void;
  icon?: ReactElement;
  allowedRoles: string[];
}
export const MenuItemHorizontal: React.FC<MenuItemHorizontalProps> = ({
  link,
  text,
  callback,
  icon,
  allowedRoles,
}) => {
  const navigate = useNavigate();
  return (
    <Showforpermission allowedRoles={[...allowedRoles]}>
      <Button
        className="w-full flex flex-row justify-center" 
        bgColor={COLORS.EMS_BLUE}
        onClick={() => {
          if(link){
            navigate(link);
          }
          callback();
        }}
      >
        <HStack spacing={2} className="w-full flex">
          {icon}
          <Text fontSize="md" color="white" className="w-full flex hover:black">
            {text}
          </Text>
        </HStack>
      </Button>
    </Showforpermission>
  );
};
