import { Select } from "@chakra-ui/select";
import { ResponseType, topologyApi } from "../services/topology.service";
import React, { useEffect, useState } from "react";
import { getUriByClassName } from "../utils/toolbox";
interface CustumSelectProps{
    obj:string
    onChange : (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
    selected?: boolean | string | number | object | { [key: string]: ResponseType; } | undefined
  }

export const CustumSelect :React.FC<CustumSelectProps> = ({ obj,onChange,selected})=>{
    const { 
        useLazyGetAllQuery, 
      } = topologyApi;
    
      const [getAll,] = useLazyGetAllQuery();
    const [entityData, setEntityData] = useState<ResponseType[]>();
    
    useEffect(()=>{
        const uriForClass = getUriByClassName(obj);
        getAll({ uri: uriForClass }).then((res) => {
          setEntityData(res.data);
        });
    },[obj])
    return (
        entityData ? (
          <Select placeholder="Select option" onChange={onChange}>
            <option value={undefined}> </option>;
            {entityData.map((elt: ResponseType, index: number) => {
              return <option key={index} value={elt["id"] as string} selected={selected===elt["id"] as string}  > {elt["id"] as string}</option>;
            })} 
          </Select>
        ) : <div></div>
      );
  }