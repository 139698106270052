import { Box, Button, Skeleton, Wrap } from "@chakra-ui/react";
import { resetTopology } from "../reducers/topologyReducer";
import { ResponseType, topologyApi } from "../services/topology.service";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Showforpermission } from "../components/Showforpermission";
import { getConnectedUserId, getLoadingDataErrorByStatus, installerRoles } from "../utils/toolbox";
import { useNavigate } from "react-router-dom";
import { SmallAddIcon } from "@chakra-ui/icons";
import Site from "../components/Site";
import EmsAlert from "../components/EmsAlert";
import { store } from "../store/config";
import { selectAuth } from "../reducers/authReducer";


export const MyToposPage: React.FC = () => {
  const [listTopos, setListeTopos] = useState<ResponseType[]>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { useFindByOwnerMutation } = topologyApi
  const [getListTopos, listToposResponse] = useFindByOwnerMutation();
  const auth = selectAuth(store.getState())
  useEffect(() => {
    getListTopos({ uri: "fluxTopology", body: { id: getConnectedUserId(auth.user?.token || "") + "" } })
      .then((res) => {
        if ("data" in res) setListeTopos(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [auth?.user]);

  useEffect(() => {
    dispatch(resetTopology());
  }, []);

  return (
    <Box className="list-site">
      <div className="flex flex-row items-baseline content-center gap-4 py-4">
        <h1 className="list-site-title font-extrabold text-xl my-auto pl-8 md:px-2 ">
          Veuillez choisir un site
        </h1>
        {
          <Showforpermission allowedRoles={[...installerRoles()]}>
            <Button
              colorScheme="blue"
              w={16}
              className="my-auto"
              onClick={() => navigate("/flux-topology")}
            >
              <SmallAddIcon boxSize={6} />
            </Button>
          </Showforpermission>
        }
      </div>
      {listToposResponse.isError ? (
        <EmsAlert
          status="error"
          title={
            ("status" in listToposResponse.error
              ? getLoadingDataErrorByStatus(listToposResponse.error.status)
                .titre
              : getLoadingDataErrorByStatus(undefined).titre) +
            " Lors du chargement de la liste des sites "
          }
          description={
            "status" in listToposResponse.error
              ? getLoadingDataErrorByStatus(listToposResponse.error.status)
                .message
              : getLoadingDataErrorByStatus(undefined).message
          }
        />
      ) : listToposResponse.isLoading ? (
        <Wrap spacing="30px">
          {[1, 2, 3].map((site, index) => (
            <Skeleton
              key={index}
              className="  flex flex-col gap-y-4  w-5/6 mx-auto md:mx-0  md:w-[300px] h-64  border-b-2 shadow-md p-2;"
            >
              <div className="  "></div>
            </Skeleton>
          ))}
        </Wrap>
      ) : (
        <div className="p-2 grid grid-cols-1 sm:grid-cols-2 gap-2 md:grid-cols-3 md:gap-3 lg:grid-cols-5 2xl:grid-cols-6 2xl:gap-5  pb-36">
          {listTopos ? listTopos.length == 0 ? <div>Vous n&apos;avez pas de site !</div> :
            listTopos.map((topo, index) => (
              <>
                <Site
                  key={index}
                  id={topo.id + ""}
                  title={topo.name + ""}
                  address={topo.adresse + ""}
                  batteryCapacity={topo.capacity ? topo.capacity as string : undefined}
                  value={
                    <div className="flex flex-row justify-start gap-4 w-full">
                      {topo.pvtotalpower && topo.capacity ? (
                        <>
                          <div> Producteur : {topo.pvtotalpower as string}</div>{" "}
                          <div> Batterie : {topo.capacity as string}</div>
                        </>
                      ) : topo.pvtotalpower ? (
                        "Producteur : " + topo.pvtotalpower
                      ) : topo.pvtotalpower ? (
                        "Battrie : " + topo.capacity
                      ) : (
                        ""
                      )}
                    </div>
                  }
                  image={topo.img + ""}
                />
              </>
            )) : <></>}
        </div>
      )}
    </Box>
  );
};
