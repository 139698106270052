/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure, 
  FormLabel, 
  Spinner,
} from "@chakra-ui/react";
import { ResponseType, topologyApi } from "../services/topology.service";
import React, { useEffect, useState } from "react";
import { Field } from "@/pages/PageAdmin"; 

interface DetailsModalProps {
  setShowDetailsElement: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      id: string;
      className: string;
    }>
  >;
  id: string;
  uri: string;
  title: string;
  showDetailsElement: { show: boolean; id: string; className: string };
  obj: string;
}

export const DetailsModal: React.FC<DetailsModalProps> = ({
  setShowDetailsElement,
  uri,
  id,
  showDetailsElement,
  title,
  obj,
}) => {
  const { onClose } = useDisclosure();

  const [details, setDetails] = useState<ResponseType>();

  const { useLazyGetOneQuery, useGetByCritereMutation } = topologyApi;
  const [getOne, getOneResponse] = useLazyGetOneQuery();
  const [,setColumns] = useState<string[]>([]);
  const [fields, setFields] = useState<ResponseType[]>([]);
  const [getByCritere, getByCritereResponse] = useGetByCritereMutation();

  useEffect(() => {
    if (id) {
      getByCritere({ uri: "parametrage", body: { className: obj } })
        .unwrap()
        .then((res) => {
          const fields: Field[] = res as Field[];
          const cols: string[] = [];
          if (fields) {
            fields.map((field: Field) => {
              if (field.show) {
                cols.push(field.name);
              }
            });
            setColumns(cols);
            setFields(fields);
          }
        });
      getOne({ uri, id })
        .unwrap()
        .then((res) => {
          setDetails(res);
          setShowDetailsElement((prev: any) => {
            const data: { show: boolean; id: string; className: string } =
              prev as { show: boolean; id: string; className: string };
            return { ...data, show: true };
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setDetails(undefined);
    }
  }, [uri, id, obj]);
  return id ? (
    <Modal
      closeOnOverlayClick={false}
      isOpen={showDetailsElement.show}
      onClose={() => {
        setShowDetailsElement((prev: any) => {
          const data: { show: boolean; id: string; className: string } = prev as {
            show: boolean;
            id: string;
            className: string;
          };
          return { ...data, show: false };
        });
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent zIndex={120}>
        <ModalHeader>
          {" "}
          <div className="flex flex-col  ">
            <h1 className="font-extrabold text-xl m-2">{title}</h1>
          </div>
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody pb={6} className="overflow-y-scroll max-h-[500px]">
          {getOneResponse.isError || getByCritereResponse.isError ? (
            <div>Error</div>
          ) : getOneResponse.isLoading || getByCritereResponse.isLoading ? (
            <div className="flex flex-row justify-center">
              <Spinner size={"lg"}></Spinner>
            </div>
          ) : (
            details && (
              <form className="flex flex-col  pl-2 gap-4 mt-4">
                {fields &&
                  fields.map((field: ResponseType, index: number) => {
                    const newField: Field = field as Field;
                    if (
                      newField.name &&
                      newField.name.toLocaleLowerCase() == "id"
                    )
                      return <></>;
                    if (newField.typeField == "CLASS") {
                      const idKey = newField.name + "Id";
                      return (
                        <div key={index} className="flex flex-row">
                          <FormLabel>
                            {" "}
                            <div className="font-bold">
                              {newField.viewName
                                ? newField.viewName
                                : newField.name} :
                            </div>
                          </FormLabel>
                          <FormLabel>
                            {idKey in details ? (details[idKey] as string ? details[idKey] as string + " (Id)" : "Aucun" ) : "Aucun"}
                          </FormLabel>
                        </div>
                      );
                    } else {
                      if (typeof details[newField.name] == "boolean") {
                        return (
                          <div key={index} className="flex flex-row">
                            <FormLabel>
                              {" "}
                              <div className="font-bold">
                                {newField.viewName
                                  ? newField.viewName
                                  : newField.name} :
                              </div>
                            </FormLabel>
                            <FormLabel>
                              {(details[newField.name] as boolean)
                                ? "Oui"
                                : "Non"}
                            </FormLabel>
                          </div>
                        );
                      }
                      return (
                        <div className="flex flex-row" key={index}>
                          <FormLabel>
                            {" "}
                            <div className="font-bold">
                              {newField.viewName
                                ? newField.viewName
                                : newField.name} :
                            </div>
                          </FormLabel>
                          <FormLabel>
                            {details[newField.name] as string}
                          </FormLabel>
                        </div>
                      );
                    }
                  })}
              </form>
            )
          )}
        </ModalBody>

        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  ) : (
    <></>
  );
};
