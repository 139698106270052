import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  Button,
  Checkbox,
  Spinner,
  FormHelperText,
} from "@chakra-ui/react";
import React, { ReactNode, useEffect, useState } from "react";
import EmsAlert from "./EmsAlert";
import { Field } from "../pages/PageAdmin";
import { CustumSelect } from "./CustumSelect";
import { PATH_PREFIX_FLUXTOPOLOGY, TYPES, URIs } from "../utils/toolbox";
import { ResponseType, crudApi } from "../services/crud.service";
import { EnumSelect } from "./EnumSelect";

interface ModalUpdateProps {
  setShowUpdateNewElement: (x: boolean) => void;
  id: string;
  uri: string;
  title: string;
  showUpdateNewElement: boolean;
  obj: string;
  onUpdateOk: () => void;
}

export const ModalUpdate: React.FC<ModalUpdateProps> = ({
  setShowUpdateNewElement,
  uri,
  id,
  showUpdateNewElement,
  title,
  obj,
  onUpdateOk,
}) => {
  const { onClose } = useDisclosure();

  const [dataToModify, setDataToModify] = useState<ResponseType>();
  const [updateMessage, setUpdateMessage] = useState<ReactNode | undefined>(
    undefined
  );
  const { useUpdateMutation, useLazyGetOneQuery, useGetByCritereMutation } =
    crudApi;
  const [update, updateResponse] = useUpdateMutation();
  const [getOne, getOneResponse] = useLazyGetOneQuery();
  const [fields, setFields] = useState<ResponseType[]>([]);
  const [getByCritere, getByCritereResponse] = useGetByCritereMutation();

  const updateDataSubmit = () => {
    setUpdateMessage(undefined);
    update({ uri: uri, body: dataToModify })
      .then((res) => {
        if ("error" in res && res.error) {
          setUpdateMessage(
            <EmsAlert
              description={"Erreur lors de la modification."}
              status="error"
              title=""
            />
          );
        } else if ("data" in res && res.data) {
          setUpdateMessage(
            <EmsAlert
              description={"Modification effectuée avec succès."}
              status="success"
              title=""
            />
          );
        }
      })
      .catch(() => {
        setUpdateMessage(
          <EmsAlert
            description={"Erreur lors de la modification."}
            status="error"
            title=""
          />
        );
      });
  };
  useEffect(() => {
    if (id) {
      getByCritere({
        uri: PATH_PREFIX_FLUXTOPOLOGY + "/" + URIs.ParametrageURI,
        body: { className: obj },
      })
        .unwrap()
        .then((res) => {
          const fields: Field[] = res as Field[];
          if (fields) {
            setFields(fields);
          }
        });
      getOne({ uri, id })
        .unwrap()
        .then((res) => {
          setDataToModify(res);
          setShowUpdateNewElement(true);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setDataToModify(undefined);
    }
  }, [uri, id, obj]);


  const convertToBase64 = (file: File | null) => {
    if(!file) return
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  return id ? (
    <Modal
      closeOnOverlayClick={false}
      isOpen={showUpdateNewElement}
      onClose={() => {
        setShowUpdateNewElement(false);
        setUpdateMessage(undefined);
        onClose();
        onUpdateOk();
      }}
    >
      <ModalOverlay />
      <ModalContent zIndex={120}>
        <ModalHeader>
          {" "}
          <div className="flex flex-col  ">
            <h1 className="font-extrabold text-xl m-2">
              Modification du {title}
            </h1>
            {updateMessage && <div>{updateMessage} </div>}
          </div>
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody pb={6} className="overflow-y-scroll max-h-[500px]">
          {getOneResponse.isError || getByCritereResponse.isError ? (
            <div>Error</div>
          ) : getOneResponse.isLoading || getByCritereResponse.isLoading ? (
            <div className="flex flex-row justify-center">
              <Spinner size={"lg"}></Spinner>
            </div>
          ) : (
            dataToModify && (
              <form className="flex flex-col  pl-2 gap-4 mt-4">
                {fields &&
                  fields.map((field: ResponseType, index: number) => {
                    const newField: Field = field as Field;
                    if (
                      newField.name &&
                      newField.name.toLocaleLowerCase() == "id"
                    )
                      return <></>;
                    return (
                      <FormControl key={index}>
                        <FormLabel>{newField.viewName} </FormLabel>
                        {TYPES.BOOLEAN.includes(
                          newField.typeField.toLocaleUpperCase()
                        ) ? (
                          <Checkbox
                            size="lg"
                            name={newField.name}
                            isChecked={
                              newField.name in dataToModify
                                ? (dataToModify[newField.name] as unknown as boolean)
                                : false
                            }
                            onChange={(e) => {
                              setDataToModify((prev) => ({
                                ...prev,
                                [newField.name]: e.target.checked,
                              }));
                            }}
                          ></Checkbox>
                        ) : TYPES.NUMBER.includes(
                          newField.typeField.toLocaleUpperCase()
                        ) ? (
                          <Input
                            type="number"
                            name={newField.name}
                            defaultValue={
                              newField.name in dataToModify
                                ? (dataToModify[newField.name] as number)
                                : ""
                            }
                            onChange={(e) =>
                              setDataToModify((prev) => ({
                                ...prev,
                                [newField.name]: Number(e.target.value),
                              }))
                            }
                          />
                        ) : newField.typeField == "ENUM" ? (
                          <>
                            <EnumSelect name={newField.value ? newField.value.toLocaleLowerCase() : ""} defaultValue={
                              newField.name in dataToModify
                                ? (dataToModify[newField.name] as string)
                                : ""} onChange={(e) => {
                                  setDataToModify((prev) => ({
                                    ...prev,
                                    [newField.name]: e.target.value,
                                  }))
                                }
                                } selected={undefined} />
                          </>

                        ) : newField.typeField == "CLASS" ? (
                          <>
                            <CustumSelect
                              obj={newField.value}
                              selected={
                                newField.name + "Id" in dataToModify
                                  ? dataToModify[newField.name + "Id"]
                                  : ""
                              }
                              onChange={(e) =>
                                setDataToModify((prev) => {
                                  return {
                                    ...prev,
                                    [newField.name]: { id: e.target.value },
                                  };
                                })
                              }
                            ></CustumSelect>
                          </>
                        ) : newField.typeField == "DATE" ? (
                          <>
                            <Input
                              type={"datetime-local"}
                              name="value"
                              required
                              defaultValue={
                                newField.name in dataToModify
                                  ? (dataToModify[newField.name] as string)
                                  : ""
                              }
                              onChange={(e) =>
                                setDataToModify((prev) => ({
                                  ...prev,
                                  [newField.name]: e.target.value,
                                }))
                              }
                            />
                          </>
                        ) : newField.name.toLocaleLowerCase() == "img" ? (
                          <Input
                            as={Input}
                            type={"file"}
                            name={"file"}
                            accept="image/*"
                            required
                            onChange={(e) => {
                              const setImage = async () => {
                                const file = e.target.files
                                  ? e.target.files[0]
                                  : null;
                                const base64 = await convertToBase64(file);
                                setDataToModify((prev) => ({
                                  ...prev,
                                  [newField.name]: base64 as string,
                                }));
                              };
                              setImage();
                            }}
                          />
                        ) : (
                          <div className="flex flex-col">
                            <Input
                              type="text"
                              name={newField.name}
                              defaultValue={
                                newField.name in dataToModify
                                  ? (dataToModify[newField.name] as string)
                                  : ""
                              }
                              onChange={(e) =>
                                setDataToModify((prev) => ({
                                  ...prev,
                                  [newField.name]: e.target.value,
                                }))
                              }
                            />
                            {newField.name == "type" && (
                              <FormHelperText>
                                ENUM,DOUBLE_ARRAY, STRING, DOUBLE, INTEGER,
                                LONG, FLOAT, BOOLEAN
                              </FormHelperText>
                            )}
                            {newField.name == "typeValues" &&
                              dataToModify &&
                              dataToModify["type"] &&
                              dataToModify["type"] == "DOUBLE_ARRAY" && (
                                <FormHelperText>
                                  Donner des valeurs sous format [a,b] (exemple
                                  : [3,4]).{" "}
                                </FormHelperText>
                              )}

                            {newField.name == "typeValues" &&
                              dataToModify &&
                              dataToModify["type"] &&
                              dataToModify["type"] == "ENUM" && (
                                <FormHelperText>
                                  Donner des valeurs séparées par ; (exemple :
                                  1;2;3).{" "}
                                </FormHelperText>
                              )}
                          </div>
                        )}
                      </FormControl>
                    );
                  })}

                <Button
                  isLoading={updateResponse.isLoading}
                  colorScheme="twitter"
                  className="rounded-md md:w-1/3 mx-auto my-2"
                  size={"lg"}
                  onClick={updateDataSubmit}
                >
                  Modifier
                </Button>
              </form>
            )
          )}
        </ModalBody>

        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  ) : (
    <></>
  );
};
