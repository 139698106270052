import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import React, { ReactNode, useRef, useState } from "react";
import "../assets/scss/components/HorizontalScroll.scss";

interface HorizontalScrollProps {
  children?: ReactNode;
}
export const HorizontalScroll: React.FC<HorizontalScrollProps> = ({
  children,
}) => {
  // scroll
  const listRef = useRef<HTMLDivElement>(null);
  const scorollToLeft = () => {
    if (listRef.current)
      listRef.current.scrollBy({
        left: 50,
        behavior: "smooth",
      });
  };
  const scorollToRight = () => {
    if (listRef.current)
      listRef.current.scrollBy({
        left: -50,
        behavior: "smooth",
      });
  };
  //scroll Up
  const [scrollIntervalLeft, setScrollIntervalLeft] =
    useState<NodeJS.Timeout | null>(null);

  const handleMouseDownLeft = () => {
    // Start scrolling down continuously when the button is pressed
    setScrollIntervalLeft(setInterval(scorollToLeft, 100));
  };

  const handleMouseUpLeft = () => {
    // Stop scrolling when the button is released
    if (scrollIntervalLeft) {
      clearInterval(scrollIntervalLeft);
      setScrollIntervalLeft(null);
    }
  };
  //scroll Right
  const [scrollIntervalRight, setScrollIntervalRight] =
    useState<NodeJS.Timeout | null>(null);

  const handleMouseDownRight = () => {
    // Start scrolling down continuously when the button is pressed
    setScrollIntervalRight(setInterval(scorollToRight, -100));
  };

  const handleMouseUpRight = () => {
    // Stop scrolling when the button is released
    if (scrollIntervalRight) {
      clearInterval(scrollIntervalRight);
      setScrollIntervalRight(null);
    }
  };

  return (
    <div className="main-house__top">
      <div className="flex justify-center">
        <Button
          onClick={scorollToLeft}
          onMouseDown={handleMouseDownLeft}
          onMouseUp={handleMouseUpLeft}
          variant={"unstyled"}
        >
          <ChevronLeftIcon boxSize={8} />
        </Button>
      </div>
      <div
        ref={listRef}
        className="w-full flex justify-between my-2 gap-2 overflow-scroll max-w-full px-2 list"
      >
        {children}
      </div>
      <div className="flex justify-center">
        <Button
          onClick={scorollToRight}
          onMouseDown={handleMouseDownRight}
          onMouseUp={handleMouseUpRight}
          variant={"unstyled"}
        >
          <ChevronRightIcon boxSize={8} />
        </Button>
      </div>
    </div>
  );
};
