import React, { useEffect } from "react";
import { useSwitcherContext } from "./SwitcherContext";


const HomeBalanceDataProvider: React.FC<React.PropsWithChildren> = ({ children }) => {

    const { setSwitcherDate,setPeriod,setUnit} = useSwitcherContext();
    useEffect(() => {
        setSwitcherDate(new Date())
        setPeriod("day")
        setUnit("kWh")
    }, []);


    return <>{children}</>;
};

export default HomeBalanceDataProvider;