import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import MySolarProductionPage from "../pages/MySolarProductionPage";
import MySolarConsumptionPage from "../pages/MySolarConsumptionPage";
import MyInvoicePage from "../pages/MyInvoicePage";
import LexiconPage from "../pages/LexiconPage";
import { SwitcherProvider } from "../contexts/SwitcherContext";
import HomescreenDataProvider from "../contexts/HomescreenDataProvider";
import HomeBalanceDataProvider from "../contexts/HomeBalanceDataProvider";
import { InstallationDetailsPage } from "../pages/InstallationDetailsPage";
import { MonComptePage } from "../pages/MonComptePage";
import { PageAdmin } from "../pages/PageAdmin";
import { FluxTopologyPage } from "../pages/FluxTopologyPage";
import LoginLayout from "../layouts/LoginLayout";
import LoginPage from "../pages/LoginPage";
import { UnauthorizedPage } from "../pages/UnauthorizedPage";
import { MyToposPage } from "../pages/MyToposPage";
import { WeatherPage } from "../pages/WeatherPage";
import { RedirectPage } from "../pages/RedirectPage";
import { PrivateRoute } from "./PrivateRoute";
import { adminRoles, installerRoles, userRoles } from "../utils/toolbox";
import { HomePage } from "../pages/HomePage";
import { PageBattery } from "../pages/PageBattery";
import { PageEcoCoaching } from "../pages/PageEcoCoaching";
import { PageIndicator } from "../pages/PageIndicator";
import React from "react";

const AppRoutes: React.FC = () => {
  const location = useLocation();

  return (
    <Routes>
      {/* Route "Connexion" */}
      <Route
        path="/login"
        element={
          <LoginLayout className="min-h-screen">
            <LoginPage />
          </LoginLayout>
        }
      />
      <Route
        path="/unauthorized"
        element={
            <UnauthorizedPage />
        }
      />
      <Route element={<PrivateRoute allowedRoles={userRoles()} />}>
        <Route
          path="/redirect"
          element={
              <RedirectPage />
          }
        />
        {/* Route "Mon installation" */}
        <Route
          path="/my-installation"
          element={
            <SwitcherProvider>
              <HomescreenDataProvider>
                <HomeBalanceDataProvider>
                    <HomePage />
                </HomeBalanceDataProvider>
              </HomescreenDataProvider>
            </SwitcherProvider>
          }
        />
        {/** Meteo */}
        <Route
          path="/weather"
          element={
            <SwitcherProvider>
                <WeatherPage />
            </SwitcherProvider>
          }
        />

        {/* Route "Ma facture" */}
        <Route
          path="/my-invoice"
          element={
              <MyInvoicePage />
          }
        />
        <Route
          path="/lexicon"
          element={
              <LexiconPage />
          }
        />
        {/* Route "Lexique" */}
        <Route
          path="/lexique"
          element={
              <LexiconPage />
          }
        />

        {/* Route "Ma production solaire" */}
        <Route
          path="/my-solar-production"
          element={
            <SwitcherProvider>
                <MySolarProductionPage />
            </SwitcherProvider>
          }
        />

        {/* Route "Ma consommation globale d'énergie" */}
        <Route
          path="/my-solar-consumption"
          element={
            <SwitcherProvider>
                <MySolarConsumptionPage />
            </SwitcherProvider>
          }
        />
        {/* Route "Mon Coaching" */}
        {/**
         * 
         * 
          <Route
          path="/my-coaching"
          element={
            <SwitcherProvider>
              <MainLayout className="flex flex-col  min-h-screen">
                <PageCoaching />
              </MainLayout>
            </SwitcherProvider>
          }
        />
         */}

        <Route
          path="/indicators"
          element={
            <SwitcherProvider>
                <PageIndicator />
            </SwitcherProvider>
          }
        />

        <Route
          path="/list-sites"
          element={
              <MyToposPage />
          }
        />

        <Route
          path="/installation-details"
          element={
              <InstallationDetailsPage />
          }
        />

        <Route
          path="/mon-compte"
          element={
              <MonComptePage />
          }
        />
        <Route
          path="/my-battery"
          element={
            <SwitcherProvider>
                <PageBattery />
            </SwitcherProvider>
          }
        />
        <Route
          path="/my-coaching"
          element={
            <SwitcherProvider>
                <PageEcoCoaching />
            </SwitcherProvider>
          }
        />
      </Route>
      <Route element={<PrivateRoute allowedRoles={adminRoles()} />}>
        <Route
          path="/administration"
          element={
              <PageAdmin />
          }
        />
      </Route>
      <Route element={<PrivateRoute allowedRoles={installerRoles()} />}>
        <Route
          path="/flux-topology"
          element={
              <FluxTopologyPage />
          }
        />
      </Route>
      <Route
        path="*"
        element={<Navigate to="/redirect" replace state={{ from: location }} />}
      />
    </Routes>
  );
};

export default AppRoutes;
