import { RootState } from "@/store/config";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface TopologyStateType {
  id: string;
  batteryCapacity?: string;
}

const initialState: TopologyStateType = {
  id: "",
};

export const topologySlice = createSlice({
  name: "topology",
  initialState,
  reducers: {
    chooseTopology: (state, action: PayloadAction<TopologyStateType>) => {
      return action.payload
        ? {
            ...state,
           ...action.payload,
          }
        : { ...state };
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resetTopology: (state) => {
      return {
        ...initialState,
      };
    },
  },
});

export const topologyReducer = topologySlice.reducer;

export const getSelectedTopo = (state: RootState) => state.topology;

export const { chooseTopology, resetTopology } = topologySlice.actions;
