import React, { useState, createContext, useContext } from 'react';

type Unit = string
type Period = string

type SymbolMap = {
    [key in Unit]?: string;
}

type SwitcherContextType = {
    unit: Unit;
    setUnit: React.Dispatch<React.SetStateAction<Unit>>;
    period: Period;
    setPeriod: React.Dispatch<React.SetStateAction<Period>>;
    switcherDate : Date;
    setSwitcherDate: React.Dispatch<React.SetStateAction<Date>>;
};

export const SwitcherContext = createContext<SwitcherContextType | undefined>(undefined);

export const SwitcherProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [unit, setUnit] = useState<Unit>("kWh");
    const [period, setPeriod] = useState<Period>("day");
    const [switcherDate, setSwitcherDate] = useState<Date>(new Date());
    
    return (
        <SwitcherContext.Provider value={{ unit , setUnit, period, setPeriod,switcherDate,setSwitcherDate}}>
            {children}
        </SwitcherContext.Provider>
    );
};

export const useSwitcherContext = (): SwitcherContextType => {
    const context = useContext(SwitcherContext);
    if (!context) {
        throw new Error('useSwitcherContext doit être utilisé au sein d’un SwitcherProvider');
    }
    return context;
};

export const displayUnit = (unit: Unit, symbolMap: SymbolMap): string => { 
    return symbolMap[unit] || unit;
};