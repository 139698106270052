import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Box, Button, FormControl, FormLabel, Heading, Input } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { Link as ChakraLink } from "@chakra-ui/react";
import { Link as ReactRouterLink, useLocation } from "react-router-dom";
import { ErrorMessage, Form, Formik } from "formik";
import { useLazyGetUserByIdQuery, User, usersApi } from "../services/users.service";
import EmsAlert from "../components/EmsAlert";
import * as Yup from "yup";
import { selectAuth } from "../reducers/authReducer";
import { store } from "../store/config";
import { getConnectedUserId } from "../utils/toolbox";
export const MonComptePage: React.FC = () => {
  const location = useLocation();
  const schema = Yup.object().shape({
    firstName: Yup.string().required("Prénom est obligatoire"),
    lastName: Yup.string().required("Nom est obligatoire"),
  })
  const auth = selectAuth(store.getState())
  const [getUserById, { isLoading: getUserIsLoading }] = useLazyGetUserByIdQuery();
  const { useUpdateUserMutation } = usersApi;
  const [updateUser, { isError, isLoading, isSuccess }] =
    useUpdateUserMutation();
  const [isUpdated, setIsUpdated] = useState<boolean | undefined>(undefined)
  const [userData, setUserData] = useState<User>()
  const [initialValues, setInitialValues] = useState({
    id: userData?.id || "",
    firstName: userData?.firstName || "",
    lastName: userData?.lastName || "",
  })
  useEffect(() => {
    getUserById({ id: getConnectedUserId(auth.user?.token || "") }).
      unwrap()
      .then((user) => {
        setUserData(user)
        setInitialValues(() => ({
          id: user?.id || "",
          firstName: user?.firstName || "",
          lastName: user?.lastName || "",
        }))
      }).catch((err) => {
        console.log(err);
      })
  }, [auth.user?.token])

  return (
    <Box padding={"10px"} w={{ base: "100%", md: "50%" }}>
      <Box textAlign="start" margin={"5px"}>
        <Heading textAlign={{ base: "center", md: "start" }} marginBottom={"4px"}>
          Mon profil
        </Heading>
        <Box>
          {isLoading ? (
            <EmsAlert
              status="loading"
              title="Veuillez patientez !"
              description=""
            />
          ) : isError || isUpdated == false ? (
            <EmsAlert
              status="error"
              title="Mise à jour non effectuée. Veuillez réessayer."
              description=""
            />
          ) : isSuccess && isUpdated == true ? (
            <EmsAlert
              status="success"
              title="Mise à jour effectuée avec succès."
              description=""
            />
          ) : (
            <></>
          )}
        </Box>
      </Box>
      <Box>
        {
          getUserIsLoading ? <div>Loading</div>
            :
            <Formik
              validationSchema={schema}
              enableReinitialize={true}
              initialValues={
                initialValues
              }
              onSubmit={(values) => {
                setIsUpdated(undefined)
                updateUser(values)
                  .unwrap()
                  .then((res) => {
                    if (res) {
                      setIsUpdated(res.valueOf())
                    }
                  })
                  .catch(() => { });
              }}
            >
              {({ handleSubmit, values, getFieldProps }) => (
                <Form className="pt-4 pl-4 flex flex-col gap-2 md:w-1/2 p-2">
                  <FormControl isRequired className="control">
                    <FormLabel className="label">Nom</FormLabel>
                    <div className="flex flex-col w-full">
                      <Input type="text"
                        {...getFieldProps("lastName")} />
                      <ErrorMessage
                        name="lastName"
                        component={"div"}
                        className="text-red-500"
                      /></div>
                  </FormControl>
                  <FormControl isRequired className="control" >
                    <FormLabel className="label">Prénom</FormLabel>
                    <div className="flex flex-col w-full">
                      <Input
                        type="text"
                        {...getFieldProps("firstName")}
                      />
                      <ErrorMessage
                        name="firstName"
                        component={"div"}
                        className="text-red-500"
                      />
                    </div>
                  </FormControl>
                  <FormControl className="control">
                    <Button
                      colorScheme={"#003265"}
                      className={"rounded-md md:w-1/3 mx-auto "}
                      size={"lg"}
                      style={{
                        backgroundColor: "#003265",
                        color: "white",
                        borderWidth: "2px",
                      }}
                      _hover={{
                        backgroundColor: "#003265",
                        color: "white",
                        borderColor: "teal.600",
                      }}
                      type={"button"}
                      onClick={() => handleSubmit()}
                      isLoading={isLoading}
                      isDisabled={userData?.firstName == values["firstName"] && userData?.lastName == values["lastName"]}
                    >
                      Enregistrer
                    </Button>
                  </FormControl>
                </Form>
              )}
            </Formik>
        }


        <FormControl className="control">
          <FormLabel className="label">Mot de passe</FormLabel>
          <div className="flex flex-col md:flex-row gap-5">
            <ChakraLink
              color="teal.500"
              as={ReactRouterLink}
              to="#"
              onClick={() => {
                //TODO : 
                /*
                auth.signinRedirect({
                  action: "UPDATE_PASSWORD",
                });*/
              }}
              replace
              state={{ from: location }}
            >
              Changer le mot de passe ? <ExternalLinkIcon mx="2px" />
            </ChakraLink>
          </div>
        </FormControl>
      </Box>
    </Box>
  );
};
