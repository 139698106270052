import Panel from "../components/Panel";
import { DPEGES } from "../components/DPEGES";
import { EcoCoachingIndicators } from "../components/EcoCoachingIndicators";
import { WeatherIndicator } from "../components/WeatherIndicator";
import { ResponsiveLayout } from "../layouts/ResponsiveLayout";
import DpeGesSVG from "../components/DpeGesSVG";
import { COLORS, getLoadingDataErrorByStatus } from "../utils/toolbox";
import { Switcher } from "../components/Switcher";
import { useSwitcherContext } from "../contexts/SwitcherContext";
import { Tournesol } from "../components/Tournesol";
import "../assets/scss/pages/PageEcoCoaching.scss";
import { ScoreResponseType, coachingApi } from "../services/coaching.service";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getSelectedTopo } from "../reducers/topologyReducer";
import { store } from "../store/config";
import EmsAlert from "../components/EmsAlert";
import { Link, Skeleton } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
export const PageEcoCoaching: React.FC = () => {
  const { unit, setUnit,setPeriod } = useSwitcherContext();
  const { useLazyGetScoreByTopoQuery } = coachingApi;
  const [
    getScoreByTopo,
    {
      isLoading: getScoreIsLoading,
      error: getScoreError,
    },
  ] = useLazyGetScoreByTopoQuery();
  const selectedTopoId = getSelectedTopo(store.getState()).id;
  const navigate = useNavigate();
  const [scoreData, setScoreData] = useState<ScoreResponseType>();
  useEffect(() => {
    if (selectedTopoId == "") {
      navigate("/redirect");
    }
    setUnit("DPE");
    setPeriod("day")

  }, [selectedTopoId]);
  
  useEffect(() => {
    getScoreByTopo({ topoId: selectedTopoId })
      .unwrap()
      .then((res) => {
        setScoreData(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <ResponsiveLayout
      header={<WeatherIndicator />}
      leftSide={
        getScoreError ? (
          <div className="max-h-fit">
            <EmsAlert
              status="error"
              title={
                ("status" in getScoreError
                  ? getLoadingDataErrorByStatus(getScoreError.status).titre
                  : getLoadingDataErrorByStatus(undefined).titre) +
                " Lors du chargement des données"
              }
              description={
                "status" in getScoreError
                  ? getLoadingDataErrorByStatus(getScoreError.status).message
                  : getLoadingDataErrorByStatus(undefined).message
              }
            />
          </div>
        ) : (
          <Skeleton isLoaded={!getScoreIsLoading} className="mx-auto">
            <div className="w-full mt-4">
              <div className="flex flex-row mx-auto  w-full flex flex-row items-center max-w-fit">
                {scoreData && <Tournesol score={scoreData?.score} />}
              </div>
            </div>
          </Skeleton>
        )
      }
      rightSide={
        <div className="w-full h-full md:max-w-[500px] mx-auto py-4">
          <EcoCoachingIndicators
            meanBestHour={scoreData?.meanBestHour}
            meanSurplusHour={scoreData?.meanSurplusHour}
            meanWorstHour={scoreData?.meanWorstHour}
          />
          {getScoreError ? (
            <EmsAlert
              status="error"
              title={
                ("status" in getScoreError
                  ? getLoadingDataErrorByStatus(getScoreError.status).titre
                  : getLoadingDataErrorByStatus(undefined).titre) +
                " Lors du chargement des données"
              }
              description={
                "status" in getScoreError
                  ? getLoadingDataErrorByStatus(getScoreError.status).message
                  : getLoadingDataErrorByStatus(undefined).message
              }
            />
          ) : (
            <Skeleton isLoaded={!getScoreIsLoading}>
              <div className="px-2">
                <Panel>
                  <Switcher withDates={false} units={["GES", "DPE"]}>
                    <div className="flex flex-col gap-6">
                      <div className="flex flex-col gap-4">
                        <div className="flex flex-col gap-4">
                          <div
                            className="uppercase"
                            style={{
                              color: COLORS.EMS_BLUE,
                              fontSize: "18px",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Votre hbitation est classee (par le{" "}
                            {unit == "DPE" ? (
                              <Link
                                href="https://www.ecologie.gouv.fr/diagnostic-performance-energetique-dpe"
                                isExternal
                              >
                                DPE <ExternalLinkIcon m="2px" />
                              </Link>
                            ) : (
                              <span>GES</span>
                            )}
                            )
                          </div>
                          <div className="flex flex-row justify-center">
                            {scoreData && (
                              <DpeGesSVG
                                classe={
                                  unit == "DPE"
                                    ? scoreData?.dpe
                                    : scoreData?.ges
                                }
                                vertical={false}
                                type={unit}
                              />
                            )}
                          </div>
                        </div>
                        <div className="flex flex-col gap-4">
                          <div
                            className="uppercase"
                            style={{
                              color: COLORS.EMS_BLUE,
                              fontSize: "18px",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            D&apos;apres votre consommation actuelle vous seriez
                            classE{" "}
                          </div>
                          <div className="flex flex-row justify-center">
                            {scoreData && (
                              <DpeGesSVG
                                classe={
                                  unit == "DPE"
                                    ? scoreData?.dpeReal
                                    : scoreData?.gesReal
                                }
                                vertical={false}
                                type={unit}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row justify-center">
                        {scoreData && (
                          <DPEGES
                            dpeGes={
                              unit == "DPE" ? scoreData?.dpe : scoreData?.ges
                            }
                            myDpeGes={
                              unit == "DPE"
                                ? scoreData?.dpeReal
                                : scoreData?.gesReal
                            }
                            type={unit}
                          ></DPEGES>
                        )}
                      </div>
                    </div>
                  </Switcher>
                </Panel>
              </div>
            </Skeleton>
          )}
        </div>
      }
    />
  );
};
