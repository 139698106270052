/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  BaseQueryApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query";
import jwt_decode from "jwt-decode";
import moment from "moment";

//svg
import ClearSky from "../assets/icons/icon-ifpen-clear-sky.svg";
import ClearSkyNight from "../assets/icons/icon-ifpen-clear-sky-night.svg";
import FewClouds from "../assets/icons/icon-ifpen-few-clouds.svg";
import FewCloudsNight from "../assets/icons/icon-ifpen-few-clouds-night.svg";
import ScatteredClouds from "../assets/icons/icon-ifpen-scattered-clouds.svg";
import ScatteredCloudsNight from "../assets/icons/icon-ifpen-scattered-clouds-night.svg";
import ShowerRain from "../assets/icons/icon-ifpen-shower-rain.svg";
import ThunderStorm from "../assets/icons/icon-ifpen-thunderstorm.svg";
import Snow from "../assets/icons/icon-ifpen-snow.svg";
import Mist from "../assets/icons/icon-ifpen-mist.svg";

export const formatter = (unit: string) => {
  return (value: number, context: any) => {
    let sum = 0;
    const dataArr = context.chart.data.datasets[0].data;
    dataArr.map((data: number) => {
      return (sum += Number(data));
    });
    return value / sum > 0.1
      ? unit == "kWh" && value / 1000 > 1
        ? (value / 1000).toFixed(1) + "M"
        : value
      : ""; // Ajustez 0.05 à la limite que vous voulez
  };
};

function convertDateToUTC(date: Date) {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
}
export const getMinutesUntilUpdate = (timestamp: string): number => {
  // Convertir le timestamp en date
  let updateDate = new Date(timestamp);
  updateDate = convertDateToUTC(updateDate);
  // Obtenir la date actuelle
  const now = new Date();
  //0===> en cours sinon
  // Calculer la différence de temps en millisecondes
  const diffInMilliseconds = updateDate.getTime() - now.getTime();

  // Convertir la différence en minutes et arrondir au nombre entier le plus proche
  const diffInMinutes = Math.round(diffInMilliseconds / 1000 / 60);

  return diffInMinutes;
};

export interface TimeLabels {
  label: string;
  instant: string;
  isForecasted?: boolean;
  dayTime?: boolean;
}
export const isSameOrAfter = (date1: Date, date2: Date, period: string) => {
  if (period == "day") {
    return (
      moment(date1).isSame(moment(date2), "hour") ||
      moment(date1).isAfter(moment(date2), "hour")
    );
  }
  return (
    moment(date1).isSame(moment(date2), "date") ||
    moment(date1).isAfter(moment(date2), "date")
  );
};
export const currentTimeString = () => {
  const hour = ("0" + new Date().getHours()).slice(-2);
  const minute = ("0" + new Date().getMinutes()).slice(-2);
  return hour + "h" + minute;
};
export const formatDateHoursAndMinutes = (date: Date) => {
  const hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  const minute =
    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  return hour + "h" + minute;
};
export const getFormatedDatetime = (
  item: any,
  period: string,
  labels: Array<TimeLabels>
) => {
  if (labels.some((label) => label.instant == item.instant)) {
    return;
  }
  switch (period) {
    case "hour": {
      //get the start of nex hour
      const itemDateTime: Date = moment(new Date(item.instant * 1000)).toDate();

      const h = ("0" + moment(itemDateTime).toDate().getHours()).slice(-2);
      const m = ("0" + itemDateTime.getMinutes()).slice(-2);
      const time = h + "h" + m;
      return labels.push({
        label: time,
        instant: item.instant,
        isForecasted: item.forecasted,
        dayTime: item.dayTime,
      });
    }
     

    case "day": {
//get the start of nex hour
const itemDate: Date = moment(new Date(item.instant * 1000)).toDate();

const hour = (
  "0" + moment(itemDate).add(-1, "hour").toDate().getHours()
).slice(-2);
const minute = ("0" + itemDate.getMinutes()).slice(-2);
const datetime = hour + "h" + minute;
return labels.push({
  label: datetime,
  instant: item.instant,
  isForecasted: item.forecasted,
  dayTime: item.dayTime,
});
    }
    case "week": {
      const day = new Intl.DateTimeFormat("fr-FR", { weekday: "long" }).format(
        new Date(item.instant * 1000)
      );
      return labels.push({
        label: day,
        instant: item.instant,
        isForecasted: item.forecasted,
      });
    }
    case "month": {
      let dayOfMonth = new Date(item.instant * 1000).getDate().toString();
      dayOfMonth = parseFloat(dayOfMonth) < 10 ? "0" + dayOfMonth : dayOfMonth;
      return labels.push({
        label: dayOfMonth,
        instant: item.instant,
        isForecasted: item.forecasted,
      });
    }
    case "year": {
      let monthOfYear = new Intl.DateTimeFormat("fr-FR", {
        month: "long",
      }).format(new Date(item.instant * 1000));
      monthOfYear =
        parseFloat(monthOfYear) < 10 ? "0" + monthOfYear : monthOfYear;
      return labels.push({
        label: monthOfYear,
        instant: item.instant,
        isForecasted: item.forecasted,
      });
    }
    default:
      break;
  }
};

export const getFirstAndLastDayOfTheWeek = (date: Date) => {
  const firstWeekDate = new Date(date);
  const lastWeekDate = new Date(date);
  const weekDay = firstWeekDate.getDay();

  if (weekDay === 0) {
    firstWeekDate.setDate(firstWeekDate.getDate() - 6);
  } else if (weekDay === 1) {
    lastWeekDate.setDate(lastWeekDate.getDate() + 7 - lastWeekDate.getDay());
  } else if (weekDay >= 1) {
    firstWeekDate.setDate(firstWeekDate.getDate() - firstWeekDate.getDay() + 1);
    lastWeekDate.setDate(lastWeekDate.getDate() + 7 - lastWeekDate.getDay());
  }

  return { firstWeekDate, lastWeekDate };
};
export function getWeekNumber(date: Date): number {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  return Math.ceil(
    ((date.getTime() - firstDayOfYear.getTime()) / 86400000 +
      firstDayOfYear.getDay() +
      1) /
    7
  );
}
export const customFormatDate = (date: Date, period: string) => {
  if (period == "day") {
    const year = date.getFullYear();
    const month =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    const result = "" + year + "-" + month + "-" + day;
    return result;
  }
  return period == "month"
    ? date.getFullYear() +
    "-" +
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1 + "") +
    ""
    : period == "week"
      ? date.getFullYear() + "-W" + formatNumber(getWeekNumber(date)) + ""
      : period == "year"
        ? date.getFullYear() + ""
        : "";
};

export const formatNumber = (num: number) => {
  return num >= 0 && num < 10 ? "0" + num : num + "";
};
export const formatDateTime = (date: Date) => {
  if (!date) return "";
  return date.toLocaleDateString("fr-FR", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
};
export const formatDate = (date: Date, period: string): string => {
  if (period === "day") {
    const today = new Date();
    if (
      today.getDate() === date.getDate() &&
      today.getMonth() === date.getMonth() &&
      today.getFullYear() === date.getFullYear()
    ) {
      return "Aujourd'hui";
    }
    return date.toLocaleDateString("fr-FR", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  } else if (period === "week") {
    return "Semaine " + getWeekNumber(date) + ",  " + date.getFullYear();
  } else if (period === "month") {
    return date.toLocaleDateString("fr-FR", { month: "long" });
  } else if (period === "year") {
    return date.getFullYear() + "";
  }
  return "";
};

export const isZeroValue = (datasets: any, index: any) => {
  let isZeroValue = true;
  for (let j = 0; j < datasets.length; j++) {
    if (datasets[j].data[index] !== "0.0") {
      isZeroValue = false;
      break;
    }
  }

  return isZeroValue;
};

export const removeZeroValues = (data: any) => {
  const newLabels: string[] = [];

  const newDatasets = data.datasets.map((dataset: any, index: number) => {
    const newDataForDataset = dataset.data.filter(
      (x: any, indexElement: number) => {
        if (!isZeroValue(data.datasets, indexElement) && index === 0) {
          newLabels.push(data.labels[indexElement]);
        }
        return !isZeroValue(data.datasets, indexElement);
      }
    );
    return { ...dataset, data: newDataForDataset };
  });

  return { ...data, labels: newLabels, datasets: newDatasets };
};

export const isEqual = (date1: Date, date2: Date) => {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
};
export const isActualWeek = (date: Date) => {
  const today = new Date();
  const firstAndLastDayOfTheActualWeek = getFirstAndLastDayOfTheWeek(today);
  const firstAndLastDayOfTheSwitcherWeek = getFirstAndLastDayOfTheWeek(date);
  if (
    isEqual(
      firstAndLastDayOfTheActualWeek.firstWeekDate,
      firstAndLastDayOfTheSwitcherWeek.firstWeekDate
    ) &&
    isEqual(
      firstAndLastDayOfTheSwitcherWeek.firstWeekDate,
      firstAndLastDayOfTheSwitcherWeek.firstWeekDate
    )
  )
    return true;
};
export const TOKEN_NAME = "token";
export const isAuthorized = (roles: string[], token: string) => {
  const userRoles = getUserRoles(token)
  if (token && roles && userRoles) {
    return roles.some((r) => {
      return userRoles.some((userRole) => userRole === r);
    });
  }
  return false;
};
export const getUserFormLocalStorage = () => {
  const user = window.localStorage.getItem("user");
  if (user != null) {
    return JSON.parse(user);
  }
  return "";
};
export const getTokenFormLocalStorage = () => {
  return getUserFormLocalStorage() != "" ? getUserFormLocalStorage().token : "";
};
export const removeTokenFormLocalStorage = () => {
  return window.localStorage.removeItem(TOKEN_NAME);
};
export const saveTokenToLocalStorage = (token: string) => {
  return window.localStorage.setItem(TOKEN_NAME, token);
};
export const removeTopologyIdInLocalStorage = () => {
  return window.localStorage.removeItem("topologyId");
};
export const getTopologyIdInLocalStorage = () => {
  return window.localStorage.getItem("topologyId");
};
export const saveTopologyIdInLocalStorage = (topologyId: string) => {
  return window.localStorage.setItem("topologyId", topologyId + "");
};

export const symbolMap = {
  Euro: " €",
  kWh: " kWh",
  Pct: " %",
  day: "Jour",
  week: "Semaine",
  month: "Mois",
  year: "Année",
};

export const getDateWithTimeZonne = (date: Date) => {
  return new Date(
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset())
  );
};

export const intervalId = 1;

export enum HTTP_STATUS {
  OK = 200,
  UNAUTORISED = 401,
  INTERNAL_SERVER_ERROR = 500,
  FORBIDDEN = 403,
  BAD_REQUEST = 400,
  SESSION_EXPIRED = 0,
}
export interface AlertType {
  titre: string;
  message: string;
  type: "error" | "info" | "warning" | "success" | "loading" | undefined;
  show: boolean;
}

export const getLoginErrorByStatus = (
  status:
    | undefined
    | number
    | "FETCH_ERROR"
    | "PARSING_ERROR"
    | "TIMEOUT_ERROR"
    | "CUSTOM_ERROR"
): AlertType => {
  switch (status) {
    case HTTP_STATUS.UNAUTORISED: {
      return {
        titre: "Accès refusé",
        message:
          "Nom d'utilisateur ou mot de passe invalide, veuillez réessayer",
        show: true,
        type: "error",
      };
    }
    case HTTP_STATUS.FORBIDDEN: {
      return {
        titre: "Accès Refusé",
        message:
          "Nom d'utilisateur ou mot de passe invalide, veuillez réessayer",
        show: true,
        type: "error",
      };
    }
    case HTTP_STATUS.INTERNAL_SERVER_ERROR: {
      return {
        titre: "Problème Technique",
        message: "Une erreur interne du serveur s'est produite",
        show: true,
        type: "error",
      };
    }
    default:
      return {
        titre: "Erreur",
        message: "Une erreur s'est produite, veuillez réessayer  !",
        show: true,
        type: "error",
      };
  }
};

export const getLoadingDataErrorByStatus = (
  status:
    | undefined
    | number
    | "FETCH_ERROR"
    | "PARSING_ERROR"
    | "TIMEOUT_ERROR"
    | "CUSTOM_ERROR"
): AlertType => {
  switch (status) {
    case HTTP_STATUS.UNAUTORISED: {
      return {
        titre: "Accès refusé",
        message: "Veuillez vérifier les éléments d'authentification !",
        show: true,
        type: "error",
      };
    }
    case HTTP_STATUS.FORBIDDEN: {
      return {
        titre: "Permission Insuffisante",
        message:
          "Vos autorisations actuelles ne sont pas suffisantes pour accéder à cette ressource. Veuillez vérifier les éléments d'authentification !",
        show: true,
        type: "error",
      };
    }
    case HTTP_STATUS.INTERNAL_SERVER_ERROR: {
      return {
        titre: "Problème Technique",
        message: "Une erreur interne du serveur s'est produite",
        show: true,
        type: "error",
      };
    }
    case HTTP_STATUS.BAD_REQUEST: {
      return {
        titre: "Requête Non Valide",
        message: "Votre requête est non valide et ne peut pas être traitée.",
        show: true,
        type: "error",
      };
    }
    case HTTP_STATUS.SESSION_EXPIRED: {
      return {
        titre: "Session expirée",
        message: "Veuillez se reconnecter",
        show: true,
        type: "error",
      };
    }
    default:
      return {
        titre: "Erreur",
        message: "Une erreur s'est produite, veuillez réessayer  !",
        show: true,
        type: "error",
      };
  }
};

export const BASE_URL_REFRESH_TOKEN = `${process.env.REACT_APP_KEYCLOAK_URL}/realms/${process.env.REACT_APP_KEYCLOAK_REALM_NAME}/`;

export const customBaseQuery =
  (BASE_URL: string) =>
    // eslint-disable-next-line @typescript-eslint/no-empty-object-type
    async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: {}) => {
      const query = (baseUrl: string) =>
        fetchBaseQuery({
          baseUrl: baseUrl,
          prepareHeaders: (headers) => {
            const token = localStorage.getItem("token") + "";

            if (token) {
              headers.set("authorization", `Bearer ${token}`);
            }
            headers.set("Accept", "application/json");

            return headers;
          },
        });
      const result = await query(BASE_URL)(args, api, extraOptions);
      /*
      if (result.error?.status === 401 || result.error?.status=="FETCH_ERROR") {
              let refreshTokenResult = await query(BASE_URL_REFRESH_TOKEN) ({url : `protocol/openid-connect/token`,
              headers : (() => {
                let headersObj : Record<string,string> = {
                  "Content-Type" : 
                  "application/x-www-form-urlencoded"
                } 
                return headersObj;
              })(),
            body :  (() => {
              let auth : RefreshTokenRequestType =     
              { 
                grant_type: "refresh_token",
                client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? "",
                client_secret: process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET ?? "",
                refresh_token : localStorage.getItem("refresh_token")+""
              } 
              var formBody = [];
              for (var property in auth) {
                var encodedKey = encodeURIComponent(property);
                var encodedValue = encodeURIComponent(auth[property]);
                formBody.push(encodedKey + "=" + encodedValue);
              }
    
              return formBody.join("&");
            })(),
             method : "POST"
            },api,extraOptions) 
            if(refreshTokenResult && refreshTokenResult.data) {
              let tokenData = refreshTokenResult.data as RefreshTokenResponseType
              saveTokenToLocalStorage(tokenData.access_token);
              localStorage.setItem("refresh_token",tokenData.refresh_token)
              localStorage.setItem("expires_in",tokenData.expires_in+"");
              localStorage.setItem("date-token",Date.now()+toString());
            }else if(refreshTokenResult && refreshTokenResult.error){
              if(refreshTokenResult.error.status == 400) {
                //localStorage.setItem("REFRESH_TOKEN_ERROR","true");
                
                
                
                localStorage.clear()
                window.location.href = window.origin;
                console.log("refreshtoken expired, veuillez se connecter !");
              }
            } 
            result = await query(BASE_URL)(args, api, extraOptions);
          //}
      }
     */
      return result;
    };

export const REFRESH_TOKEN_ERROR = "REFRESH_TOKEN_ERROR";

interface PayloadToken {
  realm_access: {
    roles: string[];
  };
  sub: string;
}
export const getConnectedUserId = (token: string) => {
  try {
    const decodedToken: PayloadToken = jwt_decode(token) as PayloadToken;
    return decodedToken.sub;
  } catch (err) {
    console.log(err);
    return "";
  }
};

export const getUserRoles = (token: string) => {
  try {
    const decodedToken: PayloadToken = jwt_decode(token) as PayloadToken;
    return decodedToken.realm_access.roles;
  } catch (err) {
    console.log(err);
  }
};

//roles name
export const ROLES = {
  WEBAPP_ADMIN: "WEBAPP_ADMIN",
  WEBAPP_INSTALLER: "WEBAPP_INSTALLER",
  WEBAPP_USER: "WEBAPP_USER",
  WEBAPP_USER_SOALIS: "WEBAPP_USER_SOALIS",
  WEBAPP_USER_FRACTAL: "WEBAPP_USER_FRACTAL",
  WEBAPP_USER_URBAN_SOLAR: "WEBAPP_USER_URBAN_SOLAR",
  WEBAPP_USER_DEMO: "WEBAPP_USER_DEMO",
  WEBAPP_ADMIN_FRACTAL: "WEBAPP_ADMIN_FRACTAL",
  WEBAPP_ADMIN_SOALIS: "WEBAPP_ADMIN_SOALIS",
  WEBAPP_ADMIN_URBAN_SOLAR: "WEBAPP_ADMIN_URBAN_SOLAR",
  WEBAPP_ADMIN_IFPEN: "WEBAPP_ADMIN_IFPEN",
  WEBAPP_ADMIN_DEMO: "WEBAPP_INSTALLER_DEMO",
  WEBAPP_INSTALLER_IFPEN: "WEBAPP_INSTALLER_IFPEN",
  WEBAPP_INSTALLER_FRACTAL: "WEBAPP_INSTALLER_FRACTAL",
  WEBAPP_INSTALLER_SOALIS: "WEBAPP_INSTALLER_SOALIS",
  WEBAPP_INSTALLER_URBAN_SOLAR: "WEBAPP_INSTALLER_URBAN_SOLAR",
  WEBAPP_INSTALLER_DEMO: "WEBAPP_INSTALLER_DEMO",
};

export const userRoles = () => {
  return [
    ROLES.WEBAPP_USER,
    ROLES.WEBAPP_USER_SOALIS,
    ROLES.WEBAPP_USER_FRACTAL,
    ROLES.WEBAPP_USER_URBAN_SOLAR,
    ROLES.WEBAPP_USER_DEMO,
  ];
};
export const adminRoles = () => {
  return [
    ROLES.WEBAPP_ADMIN,
    ROLES.WEBAPP_ADMIN_SOALIS,
    ROLES.WEBAPP_ADMIN_FRACTAL,
    ROLES.WEBAPP_ADMIN_IFPEN,
    ROLES.WEBAPP_ADMIN_URBAN_SOLAR,
    ROLES.WEBAPP_ADMIN_DEMO,
  ];
};
export const installerRoles = () => {
  return [
    ROLES.WEBAPP_INSTALLER,
    ROLES.WEBAPP_INSTALLER_SOALIS,
    ROLES.WEBAPP_INSTALLER_FRACTAL,
    ROLES.WEBAPP_INSTALLER_IFPEN,
    ROLES.WEBAPP_INSTALLER_URBAN_SOLAR,
    ROLES.WEBAPP_INSTALLER_DEMO,
  ];
};

export const TYPES = {
  NUMBER: ["DOUBLE", "INTEGER", "LONG", "FLOAT"],
  STRING: ["STRING"],
  BOOLEAN: ["BOOLEAN"],
  ENUM: ["ENUM"],
};

//packages name
export const RESPONSE_DTO_PACKAGE_NAME_TOPOLOGIES = "fr.ifpen.ems.site.domain";
export const RESPONSE_DTO_PACKAGE_NAME_ENERGY_PROVIDERS =
  "fr.ifpen.ems.model.energyprovider.domain.responseDtos";
//path name
export const PATH_PREFIX_FLUXTOPOLOGY = "topologies";
export const PATH_PREFIX_ENERGYPROVIDER = "energy";
export enum ClassNames {
  //Flux-Topology
  FIELD = RESPONSE_DTO_PACKAGE_NAME_TOPOLOGIES + ".Field",
  PARAMETER_DESCRIPTOR = RESPONSE_DTO_PACKAGE_NAME_TOPOLOGIES +
  ".ParameterDescriptor",
  MEASUREMENTSOURCEPARAMETER = RESPONSE_DTO_PACKAGE_NAME_TOPOLOGIES +
  ".MeasurementSourceParameter",
  MEASUREMENTSOURCE = RESPONSE_DTO_PACKAGE_NAME_TOPOLOGIES +
  ".MeasurementSource",
  FLUXTOPOLOGY = RESPONSE_DTO_PACKAGE_NAME_TOPOLOGIES + ".FluxTopology",
  FLUXNODEPARAMETER = RESPONSE_DTO_PACKAGE_NAME_TOPOLOGIES +
  ".FluxNodeParameter",
  FLUXNODE = RESPONSE_DTO_PACKAGE_NAME_TOPOLOGIES + ".FluxNode",
  //Energy Provider
  ENERGYPROVIDER = RESPONSE_DTO_PACKAGE_NAME_ENERGY_PROVIDERS +
  ".EnergyProvider",
  CONTRACTS = RESPONSE_DTO_PACKAGE_NAME_ENERGY_PROVIDERS + ".Contracts",
  FLUXTOPOLOGIES = RESPONSE_DTO_PACKAGE_NAME_ENERGY_PROVIDERS +
  ".Flux-Topologies",
}
export const ENTITIES = [
  //Flux-Topology
  RESPONSE_DTO_PACKAGE_NAME_TOPOLOGIES + ".Field",
  RESPONSE_DTO_PACKAGE_NAME_TOPOLOGIES + ".ParameterDescriptor",
  RESPONSE_DTO_PACKAGE_NAME_TOPOLOGIES + ".MeasurementSourceParameter",
  RESPONSE_DTO_PACKAGE_NAME_TOPOLOGIES + ".MeasurementSource",
  RESPONSE_DTO_PACKAGE_NAME_TOPOLOGIES + ".FluxTopology",
  RESPONSE_DTO_PACKAGE_NAME_TOPOLOGIES + ".FluxNodeParameter",
  RESPONSE_DTO_PACKAGE_NAME_TOPOLOGIES + ".FluxNode",
  //Energy Provider
  RESPONSE_DTO_PACKAGE_NAME_ENERGY_PROVIDERS + ".EnergyProvider",
  RESPONSE_DTO_PACKAGE_NAME_ENERGY_PROVIDERS + ".Contracts",
  RESPONSE_DTO_PACKAGE_NAME_ENERGY_PROVIDERS + ".Flux-Topologies",
];
export const getNodeTypeByString = (str: string) => {
  if (str == "GROUP") return "GROUP";
  else if (str == "CONSUMER") return "CONSUMER";
  else if (str == "PRODUCER") return "PRODUCER";
  else if (str == "BATTERY") return "BATTERY";
  return "GROUP";
};
export const TYPE_FIELDS = [
  "BOOLEAN",
  "INTEGER",
  "DOUBLE",
  "CLASS",
  "ENUM",
  "DATE",
  "AUTRE",
];
export enum URIs {
  //Flux Topos
  FluxTopologyURI = "fluxTopology",
  EnergyElementURI = "energyElement",
  ParameterDescriptorURI = "parameterDescriptor",
  MeasurementSourceURI = "measurementSource",
  FluxNodeParameterURI = "fluxNodeParameter",
  FluxNodeURI = "fluxNode",
  MeasurementSourceParameterURI = "measurementSourceParameter",
  ParametrageURI = "parametrage",
  //Energy Provider
  FluxTopologiesURI = "flux-topologies",
  EnergyProvidersUri = "energy-providers",
  ContractsURI = "contracts",
}
export const getUriByClassName = (className: string) => {
  switch (className) {
    //Flux Topology
    case RESPONSE_DTO_PACKAGE_NAME_TOPOLOGIES + ".FluxTopology": {
      return URIs.FluxTopologyURI;
    }
    case RESPONSE_DTO_PACKAGE_NAME_TOPOLOGIES + ".EnergyElement": {
      return URIs.EnergyElementURI;
    }
    case RESPONSE_DTO_PACKAGE_NAME_TOPOLOGIES + ".FluxNode": {
      return URIs.FluxNodeURI;
    }
    case RESPONSE_DTO_PACKAGE_NAME_TOPOLOGIES + ".FluxNodeParameter": {
      return URIs.FluxNodeParameterURI;
    }
    case RESPONSE_DTO_PACKAGE_NAME_TOPOLOGIES + ".MeasurementSource": {
      return URIs.MeasurementSourceURI;
    }
    case RESPONSE_DTO_PACKAGE_NAME_TOPOLOGIES + ".MeasurementSourceParameter": {
      return URIs.MeasurementSourceParameterURI;
    }
    case RESPONSE_DTO_PACKAGE_NAME_TOPOLOGIES + ".ParameterDescriptor": {
      return URIs.ParameterDescriptorURI;
    }
    case RESPONSE_DTO_PACKAGE_NAME_TOPOLOGIES + ".Field": {
      return URIs.FluxNodeURI;
    }
    //Energy Providers
    case RESPONSE_DTO_PACKAGE_NAME_ENERGY_PROVIDERS + ".EnergyProvider": {
      return URIs.EnergyProvidersUri;
    }
    case RESPONSE_DTO_PACKAGE_NAME_ENERGY_PROVIDERS + ".Contracts": {
      return URIs.ContractsURI;
    }
    case RESPONSE_DTO_PACKAGE_NAME_ENERGY_PROVIDERS + ".Flux-Topologies": {
      return URIs.FluxTopologiesURI;
    }
    default:
      return URIs.FluxTopologyURI;
  }
};

//javascipt tools
export const range = (start: number, end: number) => {
  const length = end - start + 1;
  /*
      Create an array of certain length and set the elements within it from
    start value to end value.
  */
  return Array.from({ length }, (_, idx) => idx + start);
};

export const displayWeatherIcon = (iconId: string): string | undefined => {
  return weatherIconsMap[iconId] || undefined;
};

//weather icons
export const weatherIconsMap: { [key in string]?: string } = {
  //day
  "01d": ClearSky,
  "02d": FewClouds,
  "03d": ScatteredClouds,
  "04d": ScatteredClouds,
  "09d": ShowerRain,
  "10d": ShowerRain,
  "11d": ThunderStorm,
  "13d": Snow,
  "50d": Mist,
  //night
  "01n": ClearSkyNight,
  "02n": FewCloudsNight,
  "03n": ScatteredCloudsNight,
  "04n": ScatteredCloudsNight,
  "09n": ShowerRain,
  "10n": ShowerRain,
  "11n": ThunderStorm,
  "13n": Snow,
  "50n": Mist,
};

//COLORS

export enum COLORS {
  PROD = "#F27D00",
  CONSO = "#F40012",
  BATTERYUP = "#42D828",
  BATTERYDOWN = "#10AD77",
  BATTERYNONE = "#EAEAEA",
  SURPLUS = "#E02FEF",
  EMS_BLUE = "#003265",
  EMS_BLUE_LIGHT = "#0087ff",
  EMS_BLUE_SEMI_LIGHT = "#1064ad",
  EMS_GREEN = "#0FBAB8",
  GREY = "#808080"
}

//battery
export const getBatteryCapacity = (batteryCapacitystr: string) => {
  if (batteryCapacitystr) {
    const split = batteryCapacitystr.split(" ")
    if (split.length > 0) {
      return split[0];
    }
    return ""
  }
  return ""
}
export const getCapacityCharge = (capacityTotal: string, soc: number) => {
  if (!capacityTotal) {
    return ""
  }
  return Number(capacityTotal) * (1 - soc / 100)
}
export const getCapacityDeCharge = (capacityTotal: string, soc: number) => {
  if (!capacityTotal) {
    return ""
  }
  return Number(capacityTotal) * (soc / 100)
}

//types
export interface ResponseType {
  [key: string]:
  | string
  | number
  | object
  | undefined
  | { [key: string]: ResponseType };
}
