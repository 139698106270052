import  { ReactNode } from "react";
import { Alert, AlertTitle, AlertIcon, AlertDescription } from "@chakra-ui/react";
interface EmsAlertPropsType {
  status: "error" | "info" | "warning" | "success" | "loading" | undefined ;
  title: string;
  description: string | ReactNode;
  className?:string
}

const EmsAlert: React.FC<EmsAlertPropsType> = (props) => {
  return (
      <Alert  status={props.status} variant="subtle" className={"flex flex-wrap max-h-fit "+ props.className}>
        <AlertTitle fontSize="lg" className="flex flex-row my-auto">
          <AlertIcon />{props.title}
        </AlertTitle>
        <AlertDescription className="my-auto">
          {props.description}
        </AlertDescription>
      </Alert>
  );
};

export default EmsAlert;
