/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { resetTopology } from "../reducers/topologyReducer";
import { ResponseType, topologyApi } from "../services/topology.service";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { chooseTopology } from "../reducers/topologyReducer";
import { store } from "../store/config";
import { selectAuth } from "../reducers/authReducer";
import { Showforpermission } from "../components/Showforpermission";
import { Button} from "@chakra-ui/react";
import { SmallAddIcon } from "@chakra-ui/icons";
import { getConnectedUserId, getLoadingDataErrorByStatus, installerRoles } from "../utils/toolbox";
import EmsAlert from "../components/EmsAlert";
export const RedirectPage: React.FC = () => {
  const [, setListeTopos] = useState<ResponseType[]>();
  const dispatch = useDispatch();
  const { useFindByOwnerMutation } = topologyApi;
  const [getListTopos, listToposResponse] = useFindByOwnerMutation();
  const auth = selectAuth(store.getState());
  const navigate = useNavigate()
  const redirect = (data: any) => {
    if (data) {
      if (data.length == 0) {
        return <div className="flex flex-row justify-start gap-4 py-2 items-center">
          <h1>
            Vous n&apos;avez pas de sites
          </h1>
          {
            <Showforpermission allowedRoles={[...installerRoles()]}>
              <Button
                colorScheme="blue"
                w={16}
                className="my-auto"
                onClick={() => navigate("/flux-topology")}
              >
                <SmallAddIcon boxSize={6} />
              </Button>
            </Showforpermission>
          }

        </div>;
      } else if (data.length == 1) {
        dispatch(chooseTopology({ id: data[0].id as string, batteryCapacity: data[0].capacity }));
        return <Navigate to="/my-installation" />;
      } else {
        return <Navigate to="/list-sites" />;
      }
    } else {
      return <></>;
    }
  };
  useEffect(() => {
    getListTopos({
      uri: "fluxTopology",
      body: { id: getConnectedUserId(auth.user?.token || "")+ "" },
    })
      .unwrap()
      .then((res) => {
        setListeTopos(res);
      })
  }, [auth?.user]);

  useEffect(() => {
    dispatch(resetTopology());
  }, []);
  return listToposResponse.isError ? (
    <EmsAlert
      status="error"
      title={
        ("status" in listToposResponse.error
          ? getLoadingDataErrorByStatus(listToposResponse.error.status)
            .titre
          : getLoadingDataErrorByStatus(undefined).titre) +
        " Lors du chargement de la liste des Topologies "
      }
      description={
        "status" in listToposResponse.error
          ? getLoadingDataErrorByStatus(listToposResponse.error.status)
            .message
          : getLoadingDataErrorByStatus(undefined).message
      }
    />
  ) : listToposResponse.isLoading ? (
     <div></div>
  ) : (
    redirect(listToposResponse.data)
  );
};
