import { getLoadingDataErrorByStatus, intervalId } from "../utils/toolbox";
import React, { useEffect, useState } from "react";
import { useLazyGetHomeScreenDataQuery } from "../services/ems.service";
import { getSelectedTopo } from "../reducers/topologyReducer";
import { store } from "../store/config";
import { useNavigate } from "react-router-dom";
import EmsAlert from "./EmsAlert";
import { Skeleton } from "@chakra-ui/react";
import MainHouse from "./MainHouse";

export const HomeScreen: React.FC = () => {
  const [timeRemaining, setTimeRemaining] = useState(intervalId);
  const homeScreenResponse = useLazyGetHomeScreenDataQuery();
  const topologyId = getSelectedTopo(store.getState()).id;
  const navigate = useNavigate();
  useEffect(() => {
    if (topologyId == "") {
      navigate("/redirect");
    }
  }, [topologyId]);

  useEffect(() => {
    homeScreenResponse[0]({ topologyId });
    let today = new Date();
    const timer1 = setInterval(() => {
      homeScreenResponse[0]({ topologyId });
      setTimeRemaining(intervalId);
      today = new Date();
    }, intervalId * 60 * 1000);
    const start = today.getSeconds();
    const timer2 = setInterval(() => {
      if (new Date().getSeconds() >= start) {
        setTimeRemaining(60 - (new Date().getSeconds() - start));
      } else {
        setTimeRemaining(60 - (60 - start + new Date().getSeconds()));
      }
    }, 1000);
    return () => {
      window.clearInterval(timer1);
      window.clearInterval(timer2);
    };
  }, [topologyId]);
  return (
    <div className="w-full mx-auto">
      {homeScreenResponse[1].isError ? (
        <EmsAlert
          status="error"
          title={
            ("status" in homeScreenResponse[1].error
              ? getLoadingDataErrorByStatus(homeScreenResponse[1].error.status)
                  .titre
              : getLoadingDataErrorByStatus(undefined).titre) +
            " Lors du chargement des données de la maison"
          }
          description={
            "status" in homeScreenResponse[1].error
              ? getLoadingDataErrorByStatus(homeScreenResponse[1].error.status)
                  .message
              : getLoadingDataErrorByStatus(undefined).message
          }
        />
      ) : homeScreenResponse[1].isLoading ? (
        <Skeleton className=" flex w-full md:flex-none md:w-[450px]  ">
          <div className=" w-full w-[400px]"></div>
        </Skeleton>
      ) : (
        <div className="">
          <MainHouse
            supplierFlowkW={
              homeScreenResponse[1].data?.homeDiagram?.supplierFlowkW
            }
            solarFlowkW={homeScreenResponse[1].data?.homeDiagram?.solarFlowkW}
            consumptionFlowkW={
              homeScreenResponse[1].data?.homeDiagram?.consumptionFlowkW
            }
            batteryFlowkW={
              homeScreenResponse[1].data?.homeDiagram?.batteryFlowkW
            }
            batteryCharge100={
              homeScreenResponse[1].data?.homeDiagram?.batteryCharge100
            }
            nextChargeDate={
              homeScreenResponse[1].data?.homeDiagram?.nextChargeDate
            }
            pProdGlobal={homeScreenResponse[1].data?.homeDiagram?.pProdGlobal}
            pProdConsByConsumers={
              homeScreenResponse[1].data?.homeDiagram?.pProdConsByConsumers
            }
            pProdConsByBat={
              homeScreenResponse[1].data?.homeDiagram?.pProdConsByBat
            }
            pProdSentToGrid={
              homeScreenResponse[1].data?.homeDiagram?.pProdSentToGrid
            }
            pConsoGlobal={homeScreenResponse[1].data?.homeDiagram?.pConsoGlobal}
            pConsoFromProd={
              homeScreenResponse[1].data?.homeDiagram?.pConsoFromProd
            }
            pConsoFromGrid={
              homeScreenResponse[1].data?.homeDiagram?.pConsoFromGrid
            }
            pConsoFromBat={
              homeScreenResponse[1].data?.homeDiagram?.pConsoFromBat
            }
            timeRemaining={Number(timeRemaining.toFixed(2))}
          />
        </div>
      )}
    </div>
  );
};
